import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {API_URL} from '../../../modules/auth/core/_requests'
import {useNavigate} from 'react-router-dom'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

export const BuyBoxReportsPage: FC = () => {
  const [reports, setReports] = useState<any[]>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate()

  useEffect(() => {
    fetchReports()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchReports = async () => {
    setLoading(true)
    try {
      const resp = await axios.get(`${API_URL}/api/buybox-reports`)
      setReports(resp.data || [])
    } catch (err) {
      console.error('Failed to fetch reports', err)
      setError('Failed to fetch reports')
    }
    setLoading(false)
  }

  const goDetail = (reportId: number) => {
    navigate(`/buy_box_reports/${reportId}`)
  }

  const reportBodyTemplate = (rowData: any) => {
    return (
      <button className='btn btn-link' onClick={() => goDetail(rowData.reportId)}>
        {rowData.reportId}
      </button>
    )
  }

  return (
    <div style={{padding: '20px'}}>
      <button onClick={() => navigate('/apps/buy-box-analysis')} className='btn btn-secondary mb-3'>
        Back to Analysis
      </button>
      <h2>BuyBox Reports</h2>
      {error && <div className='alert alert-danger'>{error}</div>}
      <DataTable value={reports} loading={loading} responsiveLayout='scroll' paginator rows={10}>
        <Column body={reportBodyTemplate} header='Report ID' />
        <Column field='createdAt' header='CreatedAt' />
        <Column field='status' header='Status' />
        <Column field='userEmail' header='User Email' />
        <Column field='totalAsins' header='Total Asins' />
        <Column field='completedAsins' header='Completed Asins' />
      </DataTable>
    </div>
  )
}

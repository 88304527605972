import React, {FC, useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {Doughnut} from 'react-chartjs-2'
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from 'chart.js'
import {API_URL} from '../../../modules/auth/core/_requests'
import * as XLSX from 'xlsx'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'

ChartJS.register(ArcElement, Tooltip, Legend)

const SELLER_COUNT = 10

function chunkArray<T>(arr: T[], size: number): T[][] {
  const chunked: T[][] = []
  let index = 0
  while (index < arr.length) {
    chunked.push(arr.slice(index, index + size))
    index += size
  }
  return chunked
}

function mergeImages(oldList: any[], newList: any[]) {
  const oldMap = new Map<string, string>()
  for (const o of oldList) {
    oldMap.set(o.asin, o.imagesCSV || '')
  }
  for (const n of newList) {
    if (!n.imagesCSV && oldMap.has(n.asin)) {
      n.imagesCSV = oldMap.get(n.asin)
    }
  }
  return newList
}

export const BuyBoxAnalysisWrapper: FC = () => {
  const {currentUser} = useAuth()
  const userEmail = currentUser?.decoded?.email || ''
  const navigate = useNavigate()

  const [asinInput, setAsinInput] = useState('')
  const [analysisResult, setAnalysisResult] = useState<any[]>([])
  const [missingAsins, setMissingAsins] = useState<string[]>([])
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [tokensLeft, setTokensLeft] = useState<number | null>(null)
  const [reportId, setReportId] = useState<number | null>(null)

  const tokensPollingRef = useRef<any>(null)

  useEffect(() => {
    fetchTokens()
    tokensPollingRef.current = setInterval(fetchTokens, 30000)
    return () => {
      if (tokensPollingRef.current) clearInterval(tokensPollingRef.current)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetchTokens = async () => {
    try {
      const resp = await axios.get(`${API_URL}/api/buybox-reports/tokens`)
      if (resp.data && typeof resp.data.tokensLeft === 'number') {
        setTokensLeft(resp.data.tokensLeft)
      }
    } catch (err) {
      console.error('Failed to fetch tokens from backend', err)
    }
  }

  const parseAsins = (str: string): string[] => {
    return str
      .split(/[,\s]+/)
      .map((s) => s.trim().toUpperCase())
      .filter((s) => !!s)
  }

  const handleAnalyze = async () => {
    setError(null)
    setAnalysisResult([])
    setMissingAsins([])
    setReportId(null)

    const asins = parseAsins(asinInput)
    if (!asins.length) {
      setError('Please enter at least one ASIN.')
      return
    }
    setLoading(true)
    try {
      const resp = await axios.post(`${API_URL}/api/buybox-analysis`, {
        asins,
        userEmail,
      })
      const data = resp.data || {}

      if (Array.isArray(data.immediateData)) {
        setAnalysisResult(data.immediateData)
        if (Array.isArray(data.notFound)) {
          setMissingAsins(data.notFound)
        }
      }

      if (data.reportId) {
        setReportId(data.reportId)
        doOnePoll(data.reportId)
      }
    } catch (err: any) {
      console.error('Failed to fetch buy box analysis.', err)
      setError('Failed to fetch buy box analysis.')
    }
    setLoading(false)
  }

  // Only doOnePoll if DB has some data (completedAsins>0) or status='in-progress'
  const doOnePoll = async (id: number) => {
    try {
      const pollResp = await axios.get(`${API_URL}/api/buybox-reports/${id}?page=1&pageSize=9999`)
      const rpt = pollResp.data.report || {}
      const rows = pollResp.data.data || []
      // If chunk logic hasn't run yet => completedAsins=0 => skip
      if (rpt.completedAsins > 0 || rpt.status === 'in-progress') {
        const newItems = transformDBRowsToAnalysis(rows)
        const merged = mergeImages(analysisResult, newItems)
        setAnalysisResult(merged)
      }
    } catch (err) {
      console.error('Error polling partial data', err)
    }
  }

  const transformDBRowsToAnalysis = (dbRows: any[]) => {
    const resultMap: Record<string, any> = {}
    for (const row of dbRows) {
      const asin = row.asin
      const buyBoxStats: Record<string, any> = {}
      for (let i = 1; i <= SELLER_COUNT; i++) {
        const sName = row[`seller${i}Name`]
        if (!sName) continue
        buyBoxStats[`seller${i}__${sName}`] = {
          sellerName: sName,
          percentageWon: row[`seller${i}PercentageWon`] || 0,
          avgPrice: (row[`seller${i}AvgPrice`] || 0) * 100,
          isFBA: !!row[`seller${i}IsFBA`],
          lastSeen: 'DB unknown',
          avgNewOfferCount: row[`seller${i}AvgNewOfferCount`] || 0,
        }
      }
      resultMap[asin] = {
        asin,
        buyBoxStats,
        imagesCSV: row.imagesCSV || '',
      }
    }
    return Object.values(resultMap)
  }

  const buildChartData = (item: any) => {
    const stats = item.buyBoxStats || {}
    const codes = Object.keys(stats)
    const labels = codes.map((c) => stats[c].sellerName || c)
    const values = codes.map((c) => stats[c].percentageWon || 0)
    return {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: [
            '#FF6384',
            '#36A2EB',
            '#FFCE56',
            '#6CC04A',
            '#AA56FF',
            '#FF9656',
            '#FC4444',
            '#44FC44',
            '#4444FC',
            '#FFC0CB',
          ],
          hoverBackgroundColor: [
            '#FF6384cc',
            '#36A2EBcc',
            '#FFCE56cc',
            '#6CC04Acc',
            '#AA56FFcc',
            '#FF9656cc',
            '#FC4444cc',
            '#44FC44cc',
            '#4444FCcc',
            '#FFC0CBcc',
          ],
        },
      ],
    }
  }

  const renderSellerTable = (item: any) => {
    const stats = item.buyBoxStats || {}
    const codes = Object.keys(stats)
    if (!codes.length) return <div>No seller data.</div>
    return (
      <div style={{overflowX: 'auto', marginTop: '10px'}}>
        <table className='table table-sm table-bordered' style={{minWidth: '600px'}}>
          <thead>
            <tr>
              <th>Seller</th>
              <th>PercentageWon</th>
              <th>Avg Price</th>
              <th>IsFBA</th>
              <th>LastSeen</th>
              <th>AvgNewOfferCount</th>
            </tr>
          </thead>
          <tbody>
            {codes.map((code) => {
              const s = stats[code]
              return (
                <tr key={code}>
                  <td>{s.sellerName || code}</td>
                  <td>{(s.percentageWon || 0).toFixed(2)}%</td>
                  <td>{((s.avgPrice || 0) / 100).toFixed(2)}</td>
                  <td>{s.isFBA ? 'Yes' : 'No'}</td>
                  <td>{s.lastSeen}</td>
                  <td>{s.avgNewOfferCount}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  const handleExportExcel = () => {
    if (!analysisResult.length) {
      alert('No data to export.')
      return
    }
    const header: string[] = ['ASIN']
    for (let i = 1; i <= SELLER_COUNT; i++) {
      header.push(`Seller${i}Name`)
      header.push(`Seller${i}PercentageWon`)
      header.push(`Seller${i}AvgPrice`)
      header.push(`Seller${i}IsFBA`)
      header.push(`Seller${i}AvgNewOfferCount`)
    }
    const rows: any[] = []
    rows.push(header)

    analysisResult.forEach((item) => {
      const row: any[] = []
      row.push(item.asin)
      const stats = item.buyBoxStats || {}
      const codes = Object.keys(stats).slice(0, SELLER_COUNT)
      for (let i = 0; i < SELLER_COUNT; i++) {
        const code = codes[i]
        if (!code) {
          row.push('', '', '', '', '')
        } else {
          const s = stats[code]
          row.push(s.sellerName || code)
          row.push((s.percentageWon || 0).toFixed(2) + '%')
          row.push(((s.avgPrice || 0) / 100).toFixed(2))
          row.push(s.isFBA ? 'Yes' : 'No')
          row.push(s.avgNewOfferCount || 0)
        }
      }
      rows.push(row)
    })

    const ws = XLSX.utils.aoa_to_sheet(rows)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'BuyBoxAnalysis')
    XLSX.writeFile(wb, `BuyBoxAnalysis.xlsx`)
  }

  // 1) find asins that have no data in buyBoxStats => show them at top
  const noDataAsins = analysisResult
    .filter((x) => !x.buyBoxStats || Object.keys(x.buyBoxStats).length === 0)
    .map((x) => x.asin)

  // 2) only chunk asins with data
  const dataAsins = analysisResult.filter(
    (x) => x.buyBoxStats && Object.keys(x.buyBoxStats).length > 0
  )

  const chunkedData = chunkArray(dataAsins, 3)

  return (
    <div style={{width: '100%', padding: '20px'}}>
      {error && (
        <div className='alert alert-danger'>
          <strong>Error:</strong> {error}
        </div>
      )}

      <div style={{marginBottom: '10px', display: 'flex', gap: '10px', alignItems: 'center'}}>
        <div>
          <strong>Tokens Left:</strong> {tokensLeft !== null ? tokensLeft : 'N/A'}
        </div>
        <button className='btn btn-secondary' onClick={fetchTokens}>
          Refresh Tokens
        </button>
        <button className='btn btn-info' onClick={() => navigate('/buy_box_reports')}>
          View Reports
        </button>
      </div>

      <div className='row mb-3' style={{marginLeft: 0, marginRight: 0}}>
        <div className='col-md-8' style={{paddingLeft: 0, paddingRight: 0}}>
          <label className='form-label'>Enter ASINs</label>
          <input
            className='form-control'
            placeholder='B07N6LF94F,B008BLWKZ8 ...'
            value={asinInput}
            onChange={(e) => setAsinInput(e.target.value)}
          />
        </div>
        <div className='col-md-4 d-flex align-items-end' style={{paddingLeft: 0, paddingRight: 0}}>
          <button className='btn btn-primary mb-1 me-2' onClick={handleAnalyze} disabled={loading}>
            {loading ? 'Analyzing...' : 'Analyze'}
          </button>
          <button
            className='btn btn-success mb-1'
            onClick={handleExportExcel}
            disabled={!analysisResult.length}
          >
            Export to Excel
          </button>
        </div>
        <div
          style={{maxWidth: '900px', border: '2px solid rgb(28, 28, 93)'}}
          className='container p-3 m-4'
        >
          <p>
            <strong>Notice: </strong>
            After you click Analyze, your report will begin generating. It may take some time to
            analyze your ASIN’s Buy Box. You can safely close this page and return later to check
            the status of your report on the{' '}
            <a href='/buy_box_reports'>
              {' '}
              <b style={{color: '#7239ea'}}>View Reports</b>{' '}
            </a>
            page. <br></br>
            <br></br>Please feel free to use it as much as you need. We have many constantly
            refilling tokkens.
          </p>
        </div>
      </div>

      {/* Show missing/no-seller asins in a line */}
      {noDataAsins.length > 0 && (
        <div className='text-danger mb-3'>
          <strong>No data for: </strong> {noDataAsins.join(', ')}
        </div>
      )}

      {reportId && (
        <div className='mb-3'>
          <strong>ReportID:</strong> {reportId}
        </div>
      )}

      {chunkedData.map((group, idx) => (
        <div className='row' style={{marginLeft: 0, marginRight: 0}} key={idx}>
          {group.map((item) => {
            const rawImages = item.imagesCSV || ''
            const firstImage = rawImages.split(',')[0]?.trim() || ''
            const baseImage = firstImage.replace(/\.jpe?g$/i, '')
            const imageUrl = firstImage
              ? `https://m.media-amazon.com/images/I/${baseImage}._AC_SX569_.jpg`
              : ''

            // chart
            const codes = Object.keys(item.buyBoxStats)
            const chartData = {
              labels: codes.map((c) => item.buyBoxStats[c].sellerName || c),
              datasets: [
                {
                  data: codes.map((c) => item.buyBoxStats[c].percentageWon || 0),
                  backgroundColor: [
                    '#FF6384',
                    '#36A2EB',
                    '#FFCE56',
                    '#6CC04A',
                    '#AA56FF',
                    '#FF9656',
                    '#FC4444',
                    '#44FC44',
                    '#4444FC',
                    '#FFC0CB',
                  ],
                  hoverBackgroundColor: [
                    '#FF6384cc',
                    '#36A2EBcc',
                    '#FFCE56cc',
                    '#6CC04Acc',
                    '#AA56FFcc',
                    '#FF9656cc',
                    '#FC4444cc',
                    '#44FC44cc',
                    '#4444FCcc',
                    '#FFC0CBcc',
                  ],
                },
              ],
            }

            return (
              <div className='col-md-4 mb-4' key={item.asin}>
                <div
                  style={{
                    border: '1px solid #555',
                    borderRadius: '8px',
                    padding: '15px',
                    height: '600px',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <h5 style={{wordBreak: 'break-all', marginBottom: '10px'}}>ASIN: {item.asin}</h5>
                  {imageUrl && (
                    <div
                      style={{
                        width: '200px',
                        height: '200px',
                        margin: '0 auto 10px auto',
                        borderRadius: '15px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#fff',
                      }}
                    >
                      <img
                        src={imageUrl}
                        alt={item.asin}
                        style={{
                          width: '90%',
                          height: '90%',
                          objectFit: 'contain',
                          borderRadius: '15px',
                        }}
                      />
                    </div>
                  )}
                  <div style={{height: '200px'}}>
                    <Doughnut data={chartData} />
                  </div>
                  {/* Seller table */}
                  <div style={{marginTop: '10px', overflowY: 'auto', maxHeight: '180px'}}>
                    {codes.length === 0 ? (
                      <div>No seller data.</div>
                    ) : (
                      <table className='table table-sm table-bordered' style={{minWidth: '600px'}}>
                        <thead>
                          <tr>
                            <th>Seller</th>
                            <th>PercentageWon</th>
                            <th>Avg Price</th>
                            <th>IsFBA</th>
                            <th>LastSeen</th>
                            <th>AvgNewOfferCount</th>
                          </tr>
                        </thead>
                        <tbody>
                          {codes.map((code) => {
                            const s = item.buyBoxStats[code]
                            return (
                              <tr key={code}>
                                <td>{s.sellerName || code}</td>
                                <td>{(s.percentageWon || 0).toFixed(2)}%</td>
                                <td>{((s.avgPrice || 0) / 100).toFixed(2)}</td>
                                <td>{s.isFBA ? 'Yes' : 'No'}</td>
                                <td>{s.lastSeen}</td>
                                <td>{s.avgNewOfferCount}</td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      ))}
    </div>
  )
}

/**
 * BuyBoxProtectWrapper.tsx
 *
 * Single file with 2 components:
 *   - BuyBoxProtectPage
 *   - BuyBoxProtectWrapper
 *
 * Fetches GET /api/buybox-protect from the backend route.
 */
import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {API_URL} from '../../../modules/auth/core/_requests'

// Chart.js + react-chartjs-2 for the line chart
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import {Line} from 'react-chartjs-2'

// PrimeReact DataTable
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

interface ILineChartData {
  labels: string[]
  buyBoxOwnershipData: number[]
  estUnitShareData: number[]
  suppressionData: number[]
}

interface IBrandRow {
  brand: string
  asinCount: number
  inStockCount: number
  estBuyBoxSales: number
  estLostBuyBoxSales: number
  buyBoxOwnership: number
  estUnitShare: number
  estBuyBoxUnits: number
}

interface IBuyBoxProtectData {
  lineChart: ILineChartData
  brands: IBrandRow[]
}

export const BuyBoxProtectPage: FC = () => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<IBuyBoxProtectData | null>(null)

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true)
      setError(null)
      try {
        const resp = await axios.get<IBuyBoxProtectData>('/api/buybox-protect')
        setData(resp.data)
      } catch (err: any) {
        console.error(err)
        setError('Failed to load Buy Box data.')
      } finally {
        setLoading(false)
      }
    }
    fetchData()
  }, [])

  // Build the chart.js dataset
  const buildLineChartData = (chartData: ILineChartData) => {
    return {
      labels: chartData.labels,
      datasets: [
        {
          label: 'Buy Box Ownership',
          data: chartData.buyBoxOwnershipData,
          borderColor: 'red',
          backgroundColor: 'rgba(255, 0, 0, 0.1)',
          yAxisID: 'yAxis',
        },
        {
          label: 'Est. Unit Share',
          data: chartData.estUnitShareData,
          borderColor: 'black',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          yAxisID: 'yAxis',
        },
        {
          label: 'Suppression',
          data: chartData.suppressionData,
          borderColor: 'purple',
          backgroundColor: 'rgba(128, 0, 128, 0.1)',
          yAxisID: 'yAxis',
        },
      ],
    }
  }

  const lineChartOptions = {
    responsive: true,
    interaction: {
      mode: 'index' as const,
      intersect: false,
    },
    stacked: false,
    scales: {
      yAxis: {
        type: 'linear' as const,
        display: true,
        position: 'left' as const,
        min: 0,
        max: 100,
        ticks: {
          callback: function (value: number | string) {
            return value + '%'
          },
        },
      },
    },
  }

  // PrimeReact table column templates
  const brandBodyTemplate = (row: IBrandRow) => {
    return (
      <div>
        <div style={{fontWeight: 'bold'}}>{row.brand}</div>
        <div style={{fontSize: '0.85rem', color: '#ccc'}}>
          {row.inStockCount} in-stock / {row.asinCount} total
        </div>
      </div>
    )
  }

  const currencyBodyTemplate = (value: number) => {
    return `$${value.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}`
  }

  const percentageBodyTemplate = (value: number) => {
    return `${value.toFixed(2)}%`
  }

  const floatBodyTemplate = (value: number) => {
    return value.toFixed(2)
  }

  return (
    <div style={{padding: '20px'}}>
      <h2>Buy Box Protect</h2>
      {loading && <p>Loading...</p>}
      {error && <p style={{color: 'red'}}>{error}</p>}

      {data && (
        <>
          {/* LINE CHART */}
          <div style={{maxWidth: '1200px', margin: '0 auto', marginBottom: '40px'}}>
            <Line data={buildLineChartData(data.lineChart)} options={lineChartOptions} />
          </div>

          {/* BRAND TABLE */}
          <DataTable
            value={data.brands}
            responsiveLayout='scroll'
            className='p-datatable-striped p-datatable-gridlines'
            style={{marginTop: '20px'}}
          >
            <Column header='BRAND' body={(row) => brandBodyTemplate(row)} />
            <Column
              field='estBuyBoxSales'
              header='Est. Buy Box Sales'
              body={(row) => currencyBodyTemplate(row.estBuyBoxSales)}
            />
            <Column
              field='estLostBuyBoxSales'
              header='Est. Lost Buy Box Sales'
              body={(row) => currencyBodyTemplate(row.estLostBuyBoxSales)}
            />
            <Column
              field='buyBoxOwnership'
              header='Buy Box Ownership'
              body={(row) => percentageBodyTemplate(row.buyBoxOwnership)}
            />
            <Column
              field='estUnitShare'
              header='Est. Unit Share'
              body={(row) => percentageBodyTemplate(row.estUnitShare)}
            />
            <Column
              field='estBuyBoxUnits'
              header='Est. Buy Box Units'
              body={(row) => floatBodyTemplate(row.estBuyBoxUnits)}
            />
            {/* The next two are basically encompassed in brandBodyTemplate,
                but let's show them if you want separate columns. */}
            <Column field='inStockCount' header='ASINs in Stock' />
            <Column field='asinCount' header='Total ASINs' />
          </DataTable>
        </>
      )}
    </div>
  )
}

export const BuyBoxProtectWrapper: FC = () => {
  return (
    <>
      <BuyBoxProtectPage />
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useRef} from 'react'
import axios from 'axios'
import {Dropdown} from 'primereact/dropdown'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Button} from 'primereact/button'
import {Toast} from 'primereact/toast'
import {API_URL} from '../../modules/auth/core/_requests'
import * as XLSX from 'xlsx'

// Import Chart.js and react-chartjs-2
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
  TooltipItem,
} from 'chart.js'
import {Bar} from 'react-chartjs-2'

// Register Chart.js
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)

interface StaffLineItem {
  orderId: string
  orderName: string
  createdAt: string
  updatedAt: string
  closedAt: string
  locationId: number
  locationName: string
  lineItemId: number
  lineItemName: string
  quantity: number
  lineItemTotal: string
  staffId: number
  staffLoggedInId: number
  lineItemCommission: string
  tier: string
  lineItemCommissionPercent: string
  brand: string
  staffLoggedInName: string
  staffAttributedId: number
  staffAttributedName: string
  displayName: string // NEW: from backend
  refunded?: boolean
}

interface StaffRow {
  staffId: number
  staffName: string
  baseCommission: number
  totalSales: number
  commission: number
  lineItems: StaffLineItem[]
}

interface LocationOption {
  label: string
  value: string
}

const months = [
  {label: 'January', value: '1'},
  {label: 'February', value: '2'},
  {label: 'March', value: '3'},
  {label: 'April', value: '4'},
  {label: 'May', value: '5'},
  {label: 'June', value: '6'},
  {label: 'July', value: '7'},
  {label: 'August', value: '8'},
  {label: 'September', value: '9'},
  {label: 'October', value: '10'},
  {label: 'November', value: '11'},
  {label: 'December', value: '12'},
]
const years = [
  {label: '2023', value: '2023'},
  {label: '2024', value: '2024'},
  {label: '2025', value: '2025'},
  {label: '2026', value: '2026'},
]
const hardcodedLocations: LocationOption[] = [
  {label: 'All Locations', value: ''},
  {label: '530 Sawgrass Corporate Parkway', value: '35933356085'},
  {label: 'R001 - POV Sawgrass', value: '67928096942'},
  {label: 'R002 - Just One Look', value: '67928129710'},
  {label: 'R003 - Eyes on Lincoln', value: '67938123950'},
  {label: 'R004 - PV2 Dolphin', value: '67928162478'},
  {label: 'R005 - DE Mall of San Juan', value: '67938156718'},
  {label: 'R006 - DE Brickell', value: '67938189486'},
  {label: 'R008 - DE Florida Mall', value: '67938222254'},
  {label: 'R009 - Aventura', value: '67938255022'},
  {label: 'R010 - PV3 iDrive', value: '67928195246'},
  {label: 'R011 - PV4 Vineland', value: '67928228014'},
  {label: 'R014 - DE Plaza Las Americas', value: '67938287790'},
]

// date/time
function formatDateTime(dt: string): JSX.Element | string {
  if (!dt) return '-'
  const d = new Date(dt)
  if (isNaN(d.getTime())) return '-'
  const dateStr = d.toLocaleDateString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  })
  const timeStr = d.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  })
  return (
    <span>
      <span style={{display: 'block', fontSize: '0.95rem'}}>{dateStr}</span>
      <span style={{display: 'block', fontSize: '0.85rem', color: '#777'}}>{timeStr}</span>
    </span>
  )
}

export const CommissionsPage: FC = () => {
  const toast = useRef<Toast>(null)
  const [selectedMonth, setSelectedMonth] = useState<string>('' + (new Date().getMonth() + 1))
  const [selectedYear, setSelectedYear] = useState<string>('' + new Date().getFullYear())
  const [selectedLocation, setSelectedLocation] = useState<string>('')
  const [staffData, setStaffData] = useState<StaffRow[]>([])
  const [expandedRows, setExpandedRows] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const handleFetch = async () => {
    if (!selectedMonth || !selectedYear) {
      if (toast.current) {
        toast.current.show({
          severity: 'warn',
          summary: 'Validation',
          detail: 'Please select month and year',
        })
      }
      return
    }
    setLoading(true)
    try {
      const resp = await axios.get<StaffRow[]>(`${API_URL}/api/commissions`, {
        params: {
          month: selectedMonth,
          year: selectedYear,
          location: selectedLocation,
        },
      })
      setStaffData(resp.data)
      setExpandedRows(null)
      if (resp.data.length === 0 && toast.current) {
        toast.current.show({severity: 'info', summary: 'No Data', detail: 'No staff found'})
      }
    } catch (e) {
      if (toast.current) {
        toast.current.show({severity: 'error', summary: 'Error', detail: 'Failed to fetch data'})
      }
    }
    setLoading(false)
  }

  const goToOrder = (orderId: string) => {
    const url = `https://admin.shopify.com/store/point-of-view-eyewear/orders/${orderId}`
    window.open(url, '_blank')
  }

  // brand distribution => top 20
  function buildBrandChart(row: StaffRow) {
    interface BrandTotals {
      units: number
      value: number
    }
    const brandMap = new Map<string, BrandTotals>()
    row.lineItems.forEach((li) => {
      const brand = li.brand || 'Unknown'
      const old = brandMap.get(brand) || {units: 0, value: 0}
      const newUnits = old.units + li.quantity
      const newVal = old.value + parseFloat(li.lineItemTotal || '0')
      brandMap.set(brand, {units: newUnits, value: newVal})
    })
    const sorted = Array.from(brandMap.entries()).sort((a, b) => b[1].units - a[1].units)
    const top20 = sorted.slice(0, 20)
    const labels = top20.map(([b]) => b)
    const dataUnits = top20.map(([_, v]) => v.units)
    const dataValue = top20.map(([_, v]) => v.value)

    const options: ChartOptions<'bar'> = {
      responsive: true,
      plugins: {
        legend: {display: false},
        title: {display: true, text: 'Top Brands by Units Sold (Top 20)'},
        tooltip: {
          callbacks: {
            label: function (context: TooltipItem<'bar'>) {
              const idx = context.dataIndex
              const brand = labels[idx]
              const u = dataUnits[idx]
              const val = dataValue[idx]
              return `${brand}: ${u} units, $${val.toFixed(2)}`
            },
          },
        },
      },
      scales: {
        x: {ticks: {font: {size: 12}}},
        y: {ticks: {font: {size: 12}}},
      },
    }
    const data = {
      labels,
      datasets: [
        {
          label: 'Units Sold',
          data: dataUnits,
          backgroundColor: '#42A5F5',
        },
      ],
    }
    return (
      <div className='col-12 col-md-8 mb-3'>
        <Bar data={data} options={options} />
      </div>
    )
  }

  function countUniqueOrders(row: StaffRow): number {
    const setOrders = new Set<string>()
    row.lineItems.forEach((li) => setOrders.add(li.orderId))
    return setOrders.size
  }

  const rowExpansionTemplate = (row: StaffRow) => {
    const brandChart = buildBrandChart(row)
    const totalOrders = countUniqueOrders(row)

    return (
      <div style={{padding: '0.5rem', background: '#281d2e'}}>
        <div className='row'>
          <div className='col-12 col-md-4 mb-3'>
            <div className='card shadow-sm p-3 h-100'>
              <h5 className='mb-3'>Associate Summary</h5>
              <p className='mb-1'>
                <strong>Staff Name:</strong> {row.staffName}
              </p>
              <p className='mb-1'>
                <strong>Unique Orders Attributed:</strong> {totalOrders}
              </p>
              <p className='mb-1'>
                <strong>Total Line Items:</strong> {row.lineItems.length}
              </p>
              <p className='mb-1'>
                <strong>Base Commission:</strong> {row.baseCommission}%
              </p>
            </div>
          </div>
          {brandChart}
        </div>
        <DataTable value={row.lineItems} responsiveLayout='scroll'>
          <Column
            header=''
            style={{width: '50px'}}
            body={(li: StaffLineItem) => (
              <Button
                icon='pi pi-search'
                className='p-button-text p-button-plain'
                onClick={() => goToOrder(li.orderId)}
              />
            )}
          />
          <Column
            field='orderName'
            header='Order #'
            style={{width: '100px'}}
            sortable
            dataType='numeric'
          />
          <Column
            field='lineItemName'
            header='Line Item'
            style={{width: '220px'}}
            sortable
            body={(li: StaffLineItem) => {
              if (li.refunded) {
                return <span style={{color: 'red'}}>{li.lineItemName}</span>
              }
              return li.lineItemName
            }}
          />
          <Column field='displayName' header='Display Name' style={{width: '140px'}} sortable />
          <Column field='brand' header='Brand' style={{width: '120px'}} sortable />
          <Column
            field='tier'
            header='Tier'
            style={{width: '80px'}}
            sortable
            body={(li: StaffLineItem) => (li.tier === '' ? '-' : li.tier)}
          />
          <Column
            field='lineItemCommissionPercent'
            header='Comm %'
            style={{width: '80px'}}
            sortable
            dataType='numeric'
            body={(li: StaffLineItem) =>
              li.lineItemCommissionPercent === '' ? '-' : li.lineItemCommissionPercent
            }
          />
          <Column
            header='Commission'
            style={{width: '100px'}}
            sortable
            dataType='numeric'
            body={(li: StaffLineItem) => {
              if (li.lineItemCommission === '') {
                return '-'
              }
              const num = parseFloat(li.lineItemCommission)
              const str = `$${num.toFixed(2)}`
              if (li.refunded) {
                return <span style={{color: 'red'}}>{str}</span>
              }
              return str
            }}
          />
          <Column
            header='Line Total'
            style={{width: '100px'}}
            sortable
            dataType='numeric'
            body={(li: StaffLineItem) => {
              if (li.lineItemTotal === '') {
                return '-'
              }
              const amt = parseFloat(li.lineItemTotal)
              const str = `$${amt.toFixed(2)}`
              return amt < 0 ? <span style={{color: 'red'}}>{str}</span> : str
            }}
          />
          <Column
            header='Qty'
            style={{width: '60px'}}
            dataType='numeric'
            sortable
            body={(li: StaffLineItem) => li.quantity}
          />
          <Column
            header='Staff Logged in'
            style={{width: '140px'}}
            sortable
            body={(li: StaffLineItem) => li.staffLoggedInName}
          />
          <Column
            header='Attributed'
            style={{width: '140px'}}
            sortable
            body={(li: StaffLineItem) => (li.staffAttributedId > 0 ? li.staffAttributedName : '')}
          />
          <Column
            header='Created'
            style={{width: '140px'}}
            sortable
            body={(li: StaffLineItem) => formatDateTime(li.createdAt)}
          />
          <Column
            header='Updated'
            style={{width: '140px'}}
            sortable
            body={(li: StaffLineItem) => formatDateTime(li.updatedAt)}
          />
          <Column
            header='Closed'
            style={{width: '140px'}}
            sortable
            body={(li: StaffLineItem) => formatDateTime(li.closedAt)}
          />
        </DataTable>
      </div>
    )
  }

  const allowExpansion = (r: StaffRow) => r.lineItems.length > 0
  const totalSalesField = (r: StaffRow) => `$${r.totalSales.toFixed(2)}`
  const commissionField = (r: StaffRow) => `$${r.commission.toFixed(2)}`
  const baseCommField = (r: StaffRow) => `${r.baseCommission.toFixed(2)}%`

  // excel
  const exportToExcel = () => {
    const allRows: any[] = []
    staffData.forEach((st) => {
      st.lineItems.forEach((li) => {
        allRows.push({
          StaffId: st.staffId,
          StaffName: st.staffName,
          BaseCommission: `${st.baseCommission.toFixed(2)}%`,
          OrderId: li.orderId,
          OrderName: li.orderName,
          LineItemName: li.lineItemName,
          Refunded: li.refunded ? 'Yes' : 'No',
          DisplayName: li.displayName,
          Brand: li.brand,
          Tier: li.tier === '' ? '-' : li.tier,
          CommissionPercent:
            li.lineItemCommissionPercent === '' ? '-' : li.lineItemCommissionPercent,
          CommissionAmount: li.lineItemCommission === '' ? '-' : li.lineItemCommission,
          LineTotal: li.lineItemTotal === '' ? '-' : li.lineItemTotal,
          Qty: li.quantity,
          CreatedAt: li.createdAt,
          UpdatedAt: li.updatedAt,
          ClosedAt: li.closedAt,
        })
      })
    })
    if (allRows.length === 0) {
      if (toast.current) {
        toast.current.show({severity: 'info', summary: 'Export', detail: 'No data to export'})
      }
      return
    }
    const ws = XLSX.utils.json_to_sheet(allRows)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Commissions')
    XLSX.writeFile(wb, 'commissions_export.xlsx')
  }

  return (
    <div className='container mt-4'>
      <Toast ref={toast} />
      <h3>Staff Commissions</h3>
      <div className='row g-3 mb-3'>
        <div className='col-md-3'>
          <label className='form-label'>Month</label>
          <Dropdown
            value={selectedMonth}
            options={months}
            onChange={(e) => setSelectedMonth(e.value)}
            className='w-100'
            optionLabel='label'
            optionValue='value'
          />
        </div>
        <div className='col-md-3'>
          <label className='form-label'>Year</label>
          <Dropdown
            value={selectedYear}
            options={years}
            onChange={(e) => setSelectedYear(e.value)}
            className='w-100'
            optionLabel='label'
            optionValue='value'
          />
        </div>
        <div className='col-md-3'>
          <label className='form-label'>Store Location</label>
          <Dropdown
            value={selectedLocation}
            options={hardcodedLocations}
            onChange={(e) => setSelectedLocation(e.value)}
            className='w-100'
            optionLabel='label'
            optionValue='value'
          />
        </div>
        <div className='col-md-3 d-flex align-items-end gap-2'>
          <Button
            label={loading ? 'Fetching...' : 'Fetch Commissions'}
            icon='pi pi-search'
            onClick={handleFetch}
            disabled={loading}
            className='p-button-primary'
          />
          <Button
            label='Export Excel'
            icon='pi pi-file-excel'
            className='p-button-success'
            onClick={exportToExcel}
          />
        </div>
      </div>

      <DataTable
        value={staffData}
        loading={loading}
        dataKey='staffId'
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={rowExpansionTemplate}
        responsiveLayout='scroll'
        sortMode='multiple'
      >
        <Column expander={allowExpansion} style={{width: '3em'}} />
        <Column field='staffName' header='Staff Name' style={{width: '180px'}} sortable />
        <Column
          field='baseCommission'
          header='Base Comm %'
          body={baseCommField}
          style={{width: '120px'}}
          sortable
          dataType='numeric'
        />
        <Column
          field='totalSales'
          header='Total Sales'
          body={totalSalesField}
          style={{width: '120px'}}
          sortable
          dataType='numeric'
        />
        <Column
          field='commission'
          header='Commission'
          body={commissionField}
          style={{width: '120px'}}
          sortable
          dataType='numeric'
        />
      </DataTable>
    </div>
  )
}

export const CommissionsWrapper: FC = () => {
  return (
    <>
      <h1 className='mt-3 ms-3'>Commissions</h1>
      <CommissionsPage />
    </>
  )
}

// frontend/src/app/pages/dashboard/Apps/AmzProductAnalysisWrapper.tsx

import React, { useState, useEffect, FC, useRef } from 'react';
import axios from 'axios';
import { PageTitle } from '../../../../_designereyes/layout/core';
import { API_URL } from '../../../modules/auth/core/_requests';
import { io, Socket } from 'socket.io-client';
import { Toast } from 'primereact/toast';
import 'primereact/resources/themes/saga-blue/theme.css'; // Theme
import 'primereact/resources/primereact.min.css'; // Core CSS
import 'primeicons/primeicons.css'; // Icons
import 'animate.css';

interface ProgressStep {
  id: string;
  text: string;
  status: 'pending' | 'completed' | 'error';
}

const AmzProductAnalysisPage: FC = () => {
  const [productValues, setProductValues] = useState<string>('');
  const [identifierType, setIdentifierType] = useState<string>('upc');
  const [socket, setSocket] = useState<Socket | null>(null);
  const [socketId, setSocketId] = useState<string | undefined>(undefined);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isProcessingComplete, setIsProcessingComplete] = useState<boolean>(false);
  const [fileId, setFileId] = useState<string>('');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  // Initialize progressSteps as empty array
  const [progressSteps, setProgressSteps] = useState<ProgressStep[]>([]);

  // Reference to PrimeReact Toast
  const toast = useRef<Toast>(null);

  useEffect(() => {
    // Initialize Socket.IO client
    const newSocket = io(API_URL, {
      transports: ['websocket'], // Use WebSocket transport
    });

    setSocket(newSocket);

    newSocket.on('connect', () => {
      setSocketId(newSocket.id);
      console.log('Connected to Socket.IO server with ID:', newSocket.id);
      toast.current?.show({
        severity: 'success',
        summary: 'Connected',
        detail: 'Successfully connected to the server.',
        life: 3000,
      });
    });

    newSocket.on('progress', (message: string) => {
      console.log('Received progress message:', message);

      setProgressSteps((prevSteps) => {
        const updatedSteps = [...prevSteps];

        // Check if the message indicates completion
        if (message === 'File generation complete.') {
          // Add the completed step without marking any previous step
          updatedSteps.push({
            id: `${Date.now()}-${Math.random()}`,
            text: message,
            status: 'completed',
          });
          return updatedSteps;
        }

        // If there's a pending step, mark it as completed
        const lastStep = updatedSteps[updatedSteps.length - 1];
        if (lastStep && lastStep.status === 'pending') {
          updatedSteps[updatedSteps.length - 1] = { ...lastStep, status: 'completed' };
        }

        // Add the new step as pending
        updatedSteps.push({
          id: `${Date.now()}-${Math.random()}`,
          text: message,
          status: 'pending',
        });

        return updatedSteps;
      });
    });

    newSocket.on('fileReady', ({ fileId }: { fileId: string }) => {
      setFileId(fileId);
      setIsProcessingComplete(true);
      setIsProcessing(false); // Stop showing processing

      // Mark all existing steps as completed
      setProgressSteps((prevSteps) =>
        prevSteps.map((step) => ({ ...step, status: 'completed' }))
      );

      // Optionally, add a final step indicating file readiness
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'File is ready for download.',
          status: 'completed',
        },
      ]);

      // Show a success toast notification
      toast.current?.show({
        severity: 'success',
        summary: 'File Ready',
        detail: 'Your Excel file is ready for download.',
        life: 3000,
      });
    });

    newSocket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server');
      setSocket(null);
      toast.current?.show({
        severity: 'warn',
        summary: 'Disconnected',
        detail: 'Disconnected from the server.',
        life: 3000,
      });
    });

    newSocket.on('error', (error: any) => {
      console.error('Socket.IO error:', error);
      toast.current?.show({
        severity: 'error',
        summary: 'Connection Error',
        detail: 'An error occurred with the connection.',
        life: 5000,
      });
    });

    return () => {
      newSocket.disconnect();
    };
  }, []);

  const handleGetData = async () => {
    if (!socket || !socketId) {
      toast.current?.show({
        severity: 'error',
        summary: 'Connection Error',
        detail: 'Socket connection not established.',
        life: 5000,
      });
      return;
    }

    // Reset progress steps to empty array
    setProgressSteps([]);

    setIsProcessing(true);
    setIsProcessingComplete(false);

    const identifiers = productValues
      .split('\n')
      .map((value) => value.trim())
      .filter(Boolean);

    if (identifiers.length === 0) {
      toast.current?.show({
        severity: 'warn',
        summary: 'Input Required',
        detail: 'Please enter at least one identifier.',
        life: 3000,
      });
      setIsProcessing(false);
      return;
    }

    // Perform validation based on identifierType
    let invalidIdentifiers: string[] = [];
    if (identifierType === 'asin') {
      // ASINs should be 10 characters and can contain letters and numbers
      // We'll consider identifiers that are only digits as invalid
      invalidIdentifiers = identifiers.filter((id) => /^\d+$/.test(id));
    } else if (identifierType === 'upc') {
      // UPCs should contain only digits
      invalidIdentifiers = identifiers.filter((id) => /[A-Za-z]/.test(id));
    }

    if (invalidIdentifiers.length > 0) {
      // Show error toast
      toast.current?.show({
        severity: 'error',
        summary: 'Invalid Identifiers',
        detail: `The following identifiers are invalid for ${identifierType.toUpperCase()}: ${invalidIdentifiers.join(
          ', '
        )}`,
        life: 5000,
      });
      setIsProcessing(false);
      // Add an error step
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: `Invalid ${identifierType.toUpperCase()}s provided.`,
          status: 'error',
        },
      ]);
      return;
    }

    // Create FormData to send both identifiers and the file
    const formData = new FormData();
    formData.append('identifierType', identifierType);
    formData.append('socketId', socketId as string);
    formData.append('identifiers', JSON.stringify(identifiers)); // Send as JSON string
    if (selectedFile) {
      formData.append('offerFile', selectedFile);
    }

    try {
      setIsProcessing(true);

      // POST to your existing route with FormData
      await axios.post(`${API_URL}/api/amazon-product-analysis`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.current?.show({
        severity: 'info',
        summary: 'Processing Started',
        detail: 'Your data is being processed.',
        life: 3000,
      });
    } catch (error: any) {
      console.error('Error processing the data:', error);
      toast.current?.show({
        severity: 'error',
        summary: 'Processing Error',
        detail: 'An error occurred during processing.',
        life: 5000,
      });
      setIsProcessing(false);
      // Add an error step
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'An error occurred during processing.',
          status: 'error',
        },
      ]);
    }
  };

  // Handle file input change
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectedFile(event.target.files[0]);
    }
  };

  const handleDownload = async () => {
    if (!fileId) {
      toast.current?.show({
        severity: 'error',
        summary: 'Download Error',
        detail: 'File is not ready for download.',
        life: 5000,
      });
      return;
    }

    try {
      const response = await axios.get(`${API_URL}/api/amazon-product-analysis/download/${fileId}`, {
        responseType: 'blob',
      });

      // Create a link to download the file
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'amazon_product_analysis.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();

      toast.current?.show({
        severity: 'success',
        summary: 'Download Complete',
        detail: 'Your Excel file has been downloaded.',
        life: 3000,
      });

      // Option 1: Automatically reset after download
      handleReset();

      // Option 2: Uncomment the following line if you prefer manual reset
      // setIsProcessingComplete(true);
    } catch (error) {
      console.error('Error downloading the file:', error);
      toast.current?.show({
        severity: 'error',
        summary: 'Download Error',
        detail: 'An error occurred during download.',
        life: 5000,
      });
      // Add an error step
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'An error occurred during download.',
          status: 'error',
        },
      ]);
    }
  };

  // Function to reset the component's state
  const handleReset = () => {
    setProductValues('');
    setIdentifierType('upc');
    setFileId('');
    setIsProcessing(false);
    setIsProcessingComplete(false);
    setProgressSteps([]);
    setSelectedFile(null);
    toast.current?.show({
      severity: 'info',
      summary: 'Reset Complete',
      detail: 'You can now start a new analysis.',
      life: 3000,
    });
  };

  // Function to render the icon based on the status
  const renderIcon = (status: 'pending' | 'completed' | 'error') => {
    if (status === 'pending') {
      return <i className='pi pi-spin pi-spinner'></i>;
    } else if (status === 'completed') {
      return <i className='pi pi-check text-success'></i>;
    } else if (status === 'error') {
      return <i className='pi pi-times text-danger'></i>;
    }
    return null;
  };

  return (
    <div className='card m-1 text-light'>
      {/* PrimeReact Toast Component */}
      <Toast ref={toast} position='bottom-left' />

      <div className='card-body'>
        {/* Identifier Type Radio Buttons */}
        <div className='mb-4 mt-3'>
          <label className='form-label text-white me-3'>Select Identifier Type:</label>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              id='identifierTypeUpc'
              value='upc'
              checked={identifierType === 'upc'}
              onChange={(e) => setIdentifierType(e.target.value)}
            />
            <label className='form-check-label text-white' htmlFor='identifierTypeUpc'>
              UPC
            </label>
          </div>
          <div className='form-check form-check-inline'>
            <input
              className='form-check-input'
              type='radio'
              id='identifierTypeAsin'
              value='asin'
              checked={identifierType === 'asin'}
              onChange={(e) => setIdentifierType(e.target.value)}
            />
            <label className='form-check-label text-white' htmlFor='identifierTypeAsin'>
              ASIN
            </label>
          </div>
        </div>

        {/* File input for vendor-offer Excel */}
        <div className="mb-4 text-white">
          <label className="form-label text-white me-3">
            Upload Offers (Excel):
          </label>
          <input
            type="file"
            className="form-input"
            accept=".xlsx,.xls"
            onChange={handleFileChange}
          />
        </div>

        {/* Text area for identifiers */}
        <div className='my-4'>
          <label htmlFor='productValues' className='form-label text-white'>
            Enter {identifierType.toUpperCase()}s (one per line):
          </label>
          <textarea
            className='form-control'
            id='productValues'
            rows={8}
            value={productValues}
            onChange={(e) => setProductValues(e.target.value)}
          ></textarea>
        </div>

        <div className='text-center my-5'>
          {/* Progress Steps */}
          {progressSteps.length > 0 && (
            <div className='mb-3' style={{ fontSize: '0.85rem' }}>
              <ul className='list-unstyled mt-5 d-flex flex-wrap text-white'>
                {progressSteps.map((step) => (
                  <li
                    key={step.id}
                    className={`d-flex align-items-center mb-2 w-50 ${
                      step.status === 'error' ? 'text-danger' : ''
                    }`}
                  >
                    <span className='me-2'>{renderIcon(step.status)}</span>
                    <span>{step.text}</span>
                  </li>
                ))}
              </ul>
            </div>
          )}

          {/* Buttons */}
          {!isProcessingComplete ? (
            <button
              type='button'
              className='btn btn-success my-3'
              onClick={handleGetData}
              disabled={isProcessing || !socketId}
            >
              {isProcessing ? 'Processing...' : 'Get Data'}
            </button>
          ) : (
            <div className='d-flex justify-content-center'>
              <button type='button' className='btn btn-primary my-3 me-2' onClick={handleDownload}>
                Download Excel File
              </button>
              <button type='button' className='btn btn-secondary my-3' onClick={handleReset}>
                Reset
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const AmzProductAnalysisWrapper: FC = () => {
  return (
    <>
      <PageTitle>Amazon Product Analysis</PageTitle>
      <AmzProductAnalysisPage />
    </>
  );
};

// src/app/pages/dashboard/Apps/AmazonMAPViolationsWrapper.tsx

import React, {useState, useEffect, FC, useRef} from 'react'
import {PageTitle} from '../../../../_designereyes/layout/core'
import {useAuth} from '../../../modules/auth'
import {Spinner, Form, Button, Card, Row, Col, Table} from 'react-bootstrap'
import {io, Socket} from 'socket.io-client'
import {API_URL} from '../../../modules/auth/core/_requests'
import {Toast} from 'primereact/toast'
import Badge from 'react-bootstrap/Badge'
import 'primereact/resources/themes/saga-blue/theme.css' // Theme
import 'primereact/resources/primereact.min.css' // Core CSS
import 'primeicons/primeicons.css' // Icons
import 'animate.css'
import './AmazonMAPViolationsWrapper.css'

// API Functions
const fetchProducts = async (brand: string, socketId: string) => {
  const response = await fetch(`${API_URL}/api/map-violations/products`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({brand, socketId}),
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const data = await response.json()
  return data
}

const fetchMAPViolations = async (asins: string[], socketId: string) => {
  const uniqueAsins = Array.from(new Set(asins))
  const response = await fetch(`${API_URL}/api/map-violations/violations`, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({asins: uniqueAsins, socketId}),
  })

  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  const data = await response.json()
  return data
}

// TypeScript Interfaces
interface Product {
  asin: string
  upc: string
  productName: string
  buyBoxPrice: number
  imageUrl: string // Note: We'll remove this from the front-end display
}

interface Seller {
  sellerName: string
  price?: number // Made optional
  sellerUrl: string
}

interface Violation {
  asin: string
  productName: string
  mapPrice?: number // Made optional
  lowestPrice?: number // Made optional
  priceDifference?: number // Made optional
  violatingSellerInfo: Seller[] // Updated to include complete seller info
}

interface NonViolation {
  asin: string
  productName: string
  mapPrice: number
  lowestPrice: number
  priceDifference: number
  sellers: Seller[]
}

interface Summary {
  totalAsins: number
  totalAsinsChecked: number
  asinsWithViolations: number
  uniqueViolatingSellers: number
  skippedAsins: number
  failedAsins: number
}

interface ProgressStep {
  id: string
  text: string
  status: 'pending' | 'completed' | 'error'
}

const AmazonMAPViolationsPage: FC = () => {
  const {currentUser} = useAuth()
  const [selectedBrand, setSelectedBrand] = useState<string>('')
  const [products, setProducts] = useState<Product[]>([])
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([])
  const [selectedAsins, setSelectedAsins] = useState<string[]>([])
  const [asinInput, setAsinInput] = useState<string>('')
  const [socket, setSocket] = useState<Socket | null>(null)
  const [socketId, setSocketId] = useState<string | undefined>(undefined)
  const [isFetchingProducts, setIsFetchingProducts] = useState<boolean>(false)
  const [progressSteps, setProgressSteps] = useState<ProgressStep[]>([])
  const [violations, setViolations] = useState<{[asin: string]: Violation}>({})
  const [nonViolations, setNonViolations] = useState<NonViolation[]>([])
  const [summary, setSummary] = useState<Summary | null>(null)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc')
  const toast = useRef<Toast>(null)

  // State to keep track of the current process
  const [currentProcess, setCurrentProcess] = useState<
    'fetchingProducts' | 'processingViolations' | null
  >(null)
  const currentProcessRef = useRef<'fetchingProducts' | 'processingViolations' | null>(null)

  // Update the ref whenever currentProcess changes
  useEffect(() => {
    currentProcessRef.current = currentProcess
  }, [currentProcess])

  useEffect(() => {
    // Initialize Socket.IO client
    const newSocket = io(API_URL, {
      transports: ['websocket'], // Use WebSocket transport
    })

    setSocket(newSocket)

    newSocket.on('connect', () => {
      setSocketId(newSocket.id)
      console.log('Connected to Socket.IO server with ID:', newSocket.id)
      // You can keep or remove this toast as per preference
      toast.current?.show({
        severity: 'success',
        summary: 'Connected',
        detail: 'Successfully connected to the server.',
        life: 3000,
      })
    })

    newSocket.on('progress', (message: string) => {
      console.log('Received progress message:', message)

      // Handle progress messages for both processes
      if (
        currentProcessRef.current !== 'processingViolations' &&
        currentProcessRef.current !== 'fetchingProducts'
      ) {
        // Ignore progress messages if not in a recognized process
        return
      }

      setProgressSteps((prevSteps) => {
        const updatedSteps = [...prevSteps]

        // If there's an active (pending) step, mark it as completed
        if (updatedSteps.length > 0) {
          const lastStepIndex = updatedSteps.length - 1
          if (updatedSteps[lastStepIndex].status === 'pending') {
            updatedSteps[lastStepIndex].status = 'completed'
          }
        }

        // Add the new step as pending
        updatedSteps.push({
          id: `${Date.now()}-${Math.random()}`,
          text: message,
          status: 'pending',
        })

        return updatedSteps
      })
    })

    newSocket.on('productsReady', ({products}: {products: Product[]}) => {
      setProducts(products)
      setFilteredProducts(products)
      setIsFetchingProducts(false)

      // Mark all existing steps as completed
      setProgressSteps((prevSteps) => prevSteps.map((step) => ({...step, status: 'completed'})))

      // Optionally, add a final step indicating completion
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'Products Fetched successfully.',
          status: 'completed',
        },
      ])

      // You can keep or remove this toast as per preference
      toast.current?.show({
        severity: 'success',
        summary: 'Products Ready',
        detail: 'Products have been fetched successfully.',
        life: 3000,
      })

      // Reset the current process
      setCurrentProcess(null)
    })

    newSocket.on(
      'violationsReady',
      ({
        summary,
        violations,
        nonViolations,
      }: {
        summary: Summary
        violations: {[asin: string]: Violation}
        nonViolations: NonViolation[]
      }) => {
        setViolations(violations)
        setNonViolations(nonViolations)
        setSummary(summary)

        // Mark all existing steps as completed
        setProgressSteps((prevSteps) => prevSteps.map((step) => ({...step, status: 'completed'})))

        // Optionally, add a final step indicating completion
        setProgressSteps((prevSteps) => [
          ...prevSteps,
          {
            id: `${Date.now()}-${Math.random()}`,
            text: 'MAP Violations processed successfully.',
            status: 'completed',
          },
        ])

        // You can keep or remove this toast as per preference
        toast.current?.show({
          severity: 'success',
          summary: 'MAP Violations Ready',
          detail: 'MAP Violations have been processed successfully.',
          life: 3000,
        })

        // Reset the current process
        setCurrentProcess(null)
      }
    )

    newSocket.on('error', (error: any) => {
      console.error('Socket.IO error:', error)
      // Mark all existing steps as completed
      setProgressSteps((prevSteps) => prevSteps.map((step) => ({...step, status: 'completed'})))
      // Add an error step to progressSteps
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: error.message || 'An error occurred.',
          status: 'error',
        },
      ])

      // Optionally, show a Toast notification
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: error.message || 'An error occurred.',
        life: 5000,
      })
    })

    newSocket.on('disconnect', () => {
      console.log('Disconnected from Socket.IO server')
      setSocket(null)

      // You can keep or remove this toast as per preference
      toast.current?.show({
        severity: 'warn',
        summary: 'Disconnected',
        detail: 'Disconnected from the server.',
        life: 3000,
      })
    })

    return () => {
      newSocket.disconnect()
    }
  }, [])

  // Function to handle brand selection and fetch products
  const handleFetchProducts = async () => {
    if (!selectedBrand) {
      // Optionally, add a progress step instead of or in addition to the toast
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'Brand selection is required.',
          status: 'error',
        },
      ])

      toast.current?.show({
        severity: 'error',
        summary: 'Brand Required',
        detail: 'Please select a brand to fetch products.',
        life: 3000,
      })

      return
    }

    if (!socket || !socketId) {
      // Optionally, add a progress step instead of or in addition to the toast
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'Socket connection not established.',
          status: 'error',
        },
      ])
      return
    }

    // Reset previous data and states
    setProducts([])
    setFilteredProducts([])
    setSelectedAsins([])
    setAsinInput('')
    setSearchTerm('')
    setProgressSteps([])
    setViolations({})
    setNonViolations([])
    setSummary(null)
    setIsFetchingProducts(true)

    // Set the current process
    setCurrentProcess('fetchingProducts')

    try {
      // Use the fetchProducts API function
      await fetchProducts(selectedBrand, socketId)
      // The backend will emit progress events and finally 'productsReady'
    } catch (error: any) {
      console.error('Error fetching products:', error)

      // Add an error step instead of or in addition to the toast
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'Failed to fetch products.',
          status: 'error',
        },
      ])

      // Show a Toast notification
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to fetch products.',
        life: 5000,
      })

      setIsFetchingProducts(false)
      setCurrentProcess(null)
    }
  }

  // Function to handle checkbox selection
  const handleCheckboxChange = (asin: string, checked: boolean) => {
    if (checked) {
      setSelectedAsins((prev) => Array.from(new Set([...prev, asin])))
    } else {
      setSelectedAsins((prev) => prev.filter((a) => a !== asin))
    }
  }

  const handleRowClick = (e, asin) => {
    const checkbox = document.getElementById(`checkbox-${asin}`) as HTMLInputElement
    if (checkbox) {
      checkbox.checked = !checkbox.checked
      handleCheckboxChange(asin, checkbox.checked)
    }
  }

  const handleAsinInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const input = e.target.value
    setAsinInput(input)

    const pastedAsins = input
      .split('\n')
      .map((asin) => asin.trim())
      .filter((asin) => asin !== '')

    // Remove duplicates by using a Set
    const uniqueAsins = Array.from(new Set(pastedAsins))
    setSelectedAsins(uniqueAsins)
  }

  // Function to populate ASIN input with selected ASINs
  const populateAsinInput = () => {
    if (selectedAsins.length === 0) {
      toast.current?.show({
        severity: 'warn',
        summary: 'No ASINs Selected',
        detail: 'Please select ASINs from the table to populate.',
        life: 3000,
      })
      return
    }

    setAsinInput(selectedAsins.join('\n'))
  }

  // Function to handle processing MAP Violations
  const handleProcessViolations = async () => {
    if (selectedAsins.length === 0) {
      // Optionally, add a progress step instead of or in addition to the toast
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'No ASINs selected for processing.',
          status: 'error',
        },
      ])

      toast.current?.show({
        severity: 'error',
        summary: 'No ASINs Selected',
        detail: 'Please select or paste valid ASINs to process.',
        life: 5000,
      })

      return
    }

    if (!socket || !socketId) {
      toast.current?.show({
        severity: 'error',
        summary: 'Connection Error',
        detail: 'Socket connection not established.',
        life: 5000,
      })
      return
    }

    // Reset previous violations data and states
    setViolations({})
    setNonViolations([])
    setSummary(null)

    // Reset progress steps and set current process
    setProgressSteps([])
    setCurrentProcess('processingViolations')

    try {
      // Use the fetchMAPViolations API function
      await fetchMAPViolations(selectedAsins, socketId)
      // The backend will emit progress events and finally 'violationsReady'
    } catch (error: any) {
      console.error('Error processing MAP violations:', error)

      // Add an error step instead of or in addition to the toast
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'Failed to process MAP violations.',
          status: 'error',
        },
      ])

      // Show a Toast notification
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to process MAP violations.',
        life: 5000,
      })
    }
  }

  // Function to render the icon based on the status
  const renderIcon = (status: 'pending' | 'completed' | 'error') => {
    if (status === 'pending') {
      return <i className='pi pi-spin pi-spinner'></i>
    } else if (status === 'completed') {
      return <i className='pi pi-check text-success'></i>
    } else if (status === 'error') {
      return <i className='pi pi-times text-danger'></i>
    }
    return null
  }

  // Function to export violations as CSV
  const exportViolationsCSV = () => {
    if (Object.keys(violations).length === 0) {
      // Optionally, add a progress step instead of or in addition to the toast
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'No violations to export.',
          status: 'error',
        },
      ])

      toast.current?.show({
        severity: 'error',
        summary: 'No Violations',
        detail: 'There are no violations to export.',
        life: 3000,
      })

      return
    }

    const headers = [
      'ASIN',
      'Product Name',
      'MAP Price',
      'Lowest Price',
      'Price Difference',
      'Seller Name',
      'Seller Price',
      'Seller URL',
    ]

    const rows: string[] = []

    Object.values(violations).forEach((violation) => {
      violation.violatingSellerInfo.forEach((seller) => {
        rows.push(
          [
            violation.asin,
            `"${violation.productName.replace(/"/g, '""')}"`, // Handle quotes in product names
            violation.mapPrice !== undefined ? violation.mapPrice.toFixed(2) : 'N/A',
            violation.lowestPrice !== undefined ? violation.lowestPrice.toFixed(2) : 'N/A',
            violation.priceDifference !== undefined ? violation.priceDifference.toFixed(2) : 'N/A',
            `"${seller.sellerName.replace(/"/g, '""')}"`, // Handle quotes in seller names
            seller.price !== undefined ? seller.price.toFixed(2) : 'N/A',
            seller.sellerUrl,
          ].join(',')
        )
      })
    })

    const csvContent = [headers.join(','), ...rows].join('\n')

    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'})
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'map_violations.csv')
    link.click()
  }

  // Function to export non-violations as CSV
  const exportNonViolationsCSV = () => {
    if (nonViolations.length === 0) {
      setProgressSteps((prevSteps) => [
        ...prevSteps,
        {
          id: `${Date.now()}-${Math.random()}`,
          text: 'No non-violations to export.',
          status: 'error',
        },
      ])

      toast.current?.show({
        severity: 'error',
        summary: 'No Non-Violations',
        detail: 'There are no non-violations to export.',
        life: 3000,
      })

      return
    }

    const headers = [
      'ASIN',
      'Product Name',
      'MAP Price',
      'Highest Price',
      'Price Difference',
      'Seller Name',
      'Seller Price',
      'Seller URL',
    ]

    const rows: string[] = []

    nonViolations.forEach((nonViolation) => {
      nonViolation.sellers.forEach((seller) => {
        rows.push(
          [
            nonViolation.asin,
            `"${nonViolation.productName.replace(/"/g, '""')}"`,
            nonViolation.mapPrice.toFixed(2),
            nonViolation.lowestPrice.toFixed(2),
            nonViolation.priceDifference.toFixed(2),
            `"${seller.sellerName.replace(/"/g, '""')}"`,
            seller.price !== undefined ? seller.price.toFixed(2) : 'N/A',
            seller.sellerUrl,
          ].join(',')
        )
      })
    })

    const csvContent = [headers.join(','), ...rows].join('\n')

    const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'})
    const url = URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.setAttribute('href', url)
    link.setAttribute('download', 'map_non_violations.csv')
    link.click()
  }

  // Function to handle search input
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setSearchTerm(value)
  }

  // Function to handle sorting by price
  const handleSortByPrice = () => {
    const direction = sortDirection === 'asc' ? 'desc' : 'asc'
    setSortDirection(direction)
  }

  // Effect to handle search and sorting
  useEffect(() => {
    let tempProducts = [...products]

    // Search filtering
    if (searchTerm) {
      tempProducts = tempProducts.filter(
        (product) =>
          product.asin.toLowerCase().includes(searchTerm.toLowerCase()) ||
          product.productName.toLowerCase().includes(searchTerm.toLowerCase())
      )
    }

    // Sorting by price
    tempProducts.sort((a, b) => {
      if (sortDirection === 'asc') {
        return a.buyBoxPrice - b.buyBoxPrice
      } else {
        return b.buyBoxPrice - a.buyBoxPrice
      }
    })

    setFilteredProducts(tempProducts)
  }, [products, searchTerm, sortDirection])

  return (
    <div className='card m-1 text-light'>
      {/* PrimeReact Toast Component */}
      <Toast ref={toast} position='bottom-left' />

      <div className='card-body'>
        {/* Header Section */}
        <div className='mb-4'>
          <Row className='align-items-center mt-4'>
            <Col md={6}>
              <h2>MAP Violations on Amazon</h2>
            </Col>
            <Col md={6} className='text-md-end'>
              <Button
                variant='secondary'
                onClick={handleFetchProducts}
                disabled={isFetchingProducts || !selectedBrand}
              >
                {isFetchingProducts ? (
                  <>
                    <Spinner
                      as='span'
                      animation='border'
                      size='sm'
                      role='status'
                      aria-hidden='true'
                      className='me-2'
                    />
                    Refreshing...
                  </>
                ) : (
                  'Refresh'
                )}
              </Button>
            </Col>
          </Row>
        </div>

        {/* Brand Selection */}
        <div className='card-flush mb-4'>
          <div className='card-body'>
            <Row className='align-items-end'>
              <Col className='p-0' md={4}>
                <Form.Label>Select Brand</Form.Label>
                <Form.Select
                  value={selectedBrand}
                  onChange={(e) => setSelectedBrand(e.target.value)}
                  disabled={isFetchingProducts}
                >
                  <option value=''>-- Select Brand --</option>
                  <option value='Armani Exchange'>Armani Exchange</option>
                  <option value='Arnette'>Arnette</option>
                  <option value='Burberry'>Burberry</option>
                  <option value='Calvin Klein'>Calvin Klein</option>
                  <option value='Carrera'>Carrera</option>
                  <option value='Costa Del Mar'>Costa</option>
                  <option value='Dolce & Gabbana'>Dolce & Gabbana</option>
                  <option value='Emporio Armani'>Emporio Armani</option>
                  <option value='Ferragamo'>Ferragamo</option>
                  <option value='GAP'>GAP</option>
                  <option value='GUCCI'>GUCCI</option>
                  <option value='Guess'>Guess</option>
                  <option value='Harley-Davidson'>Harley-Davidson</option>
                  <option value='Nike'>Nike</option>
                  <option value='Oakley'>Oakley</option>
                  <option value='ray-ban'>Ray Ban</option>
                  <option value='Tiffany & Co.'>Tiffany & Co.</option>
                  <option value='Tom Ford'>Tom Ford</option>
                  <option value='TORY BURCH'>TORY BURCH</option>
                  <option value='Under Armour'>Under Armour</option>
                  <option value='Versace'>Versace</option>
                  {/* Add more brands as needed */}
                </Form.Select>
              </Col>
              <Col md={4}>
                <Button
                  className='mt-4'
                  onClick={handleFetchProducts}
                  disabled={isFetchingProducts || !selectedBrand}
                >
                  {isFetchingProducts ? (
                    <>
                      <Spinner
                        as='span'
                        animation='border'
                        size='sm'
                        role='status'
                        aria-hidden='true'
                        className='me-2'
                      />
                      Fetching Products...
                    </>
                  ) : (
                    'Fetch Products'
                  )}
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        {/* Products Table */}
        {products.length > 0 && (
          <div className='card card-flush mb-4'>
            <div className='card-header'>
              <h2 className='card-title m-0'>
                {' '}
                Products &nbsp;{' '}
                <Badge bg='success' text='white'>
                  {' '}
                  {products.length}
                </Badge>{' '}
              </h2>
            </div>
            {/* Search Input and Sorting */}
            {products.length > 0 && (
              <div className='mb-6 card-body'>
                <Row className='align-items-center justify-content-between'>
                  <Col md={5}>
                    <Form.Control
                      type='text'
                      placeholder='Search by ASIN or Product Name'
                      value={searchTerm}
                      onChange={handleSearch}
                    />
                  </Col>
                  <Col md={5} className='text-md-end mt-3 mt-md-0'>
                    <Button variant='info' onClick={handleSortByPrice}>
                      Sort by Price ({sortDirection === 'asc' ? 'Ascending' : 'Descending'})
                    </Button>
                  </Col>
                </Row>
              </div>
            )}

            <div className='card-body data-table-container table-product-data'>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th className='text-white w-m-120'>
                      <Form.Check
                        type='checkbox'
                        label='Select All'
                        className='d-flex text-white align-items-center select-all-label'
                        checked={
                          selectedAsins.length === filteredProducts.length &&
                          filteredProducts.length > 0
                        }
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedAsins(filteredProducts.map((p) => p.asin))
                          } else {
                            setSelectedAsins([])
                          }
                        }}
                        id='selectAllCheckbox'
                      />
                    </th>
                    <th className='fit-content'>ASIN</th>
                    <th className='fit-content'>UPC</th>
                    <th>Product Name</th>
                    <th>
                      Price{' '}
                      <Button variant='link' onClick={handleSortByPrice}>
                        {sortDirection === 'asc' ? (
                          <i className='pi pi-sort-amount-up'></i>
                        ) : (
                          <i className='pi pi-sort-amount-down'></i>
                        )}
                      </Button>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProducts.map((product) => (
                    <tr key={product.asin} onClick={(e) => handleRowClick(e, product.asin)}>
                      <td className='checkbox-td'>
                        <label
                          htmlFor={`checkbox-${product.asin}`}
                          style={{display: 'flex', alignItems: 'center', cursor: 'pointer'}}
                        >
                          <Form.Check
                            type='checkbox'
                            id={`checkbox-${product.asin}`}
                            checked={selectedAsins.includes(product.asin)}
                            onChange={(e) => handleCheckboxChange(product.asin, e.target.checked)}
                            style={{marginRight: '8px'}} // Optional: space between checkbox and image
                          />
                          <img
                            src={`https://m.media-amazon.com/images/I/${product.imageUrl}`}
                            alt={`Image of ${product.asin}`}
                            style={{width: '70px', height: 'auto', cursor: 'pointer'}} // Adjust image size
                          />
                        </label>
                      </td>
                      <td>{product.asin}</td>
                      <td>
                        {product.upc && product.upc.startsWith('0')
                          ? product.upc.substring(1)
                          : product.upc}
                      </td>
                      <td className='product-name-td'>
                        <span className='product-name'>{product.productName}</span>
                      </td>
                      <td>
                        $
                        {product.buyBoxPrice !== null && product.buyBoxPrice !== undefined
                          ? product.buyBoxPrice.toFixed(2)
                          : 'N/A'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* Progress Steps */}
        {progressSteps.length > 0 && (
          <div className='card-flush mb-4 p-2'>
            <div className='mb-4 mt-4 text-white progress-bullets'>
              <h3 className='text-primary m-0 mb-3'>Progress Report</h3>
              <Row>
                {progressSteps.map((step) => (
                  <Col key={step.id} xs={12} md={6} className='mb-3'>
                    <div className='d-flex align-items-center'>
                      <span className='progress-bullet me-2'>{renderIcon(step.status)}</span>
                      <span
                        className={`step-text 
                        ${step.text.includes('Error Sellers') ? 'text-danger' : ''}
                        ${step.text.includes('Processing Complete') ? 'text-success' : ''}
                        ${
                          step.text.includes('MAP Price not found') ||
                          step.text.includes('Error Scraping') ||
                          step.text.includes('UPC not found')
                            ? 'text-danger'
                            : ''
                        }`}
                      >
                        {step.text.includes(':') ? (
                          <>
                            <strong>{step.text.split(':')[0]}:</strong> {step.text.split(':')[1]}
                          </>
                        ) : (
                          step.text
                        )}
                      </span>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        )}

        {/* Summary Section */}
        {summary && (
          <div className='mb-4 summary-report-container'>
            <div className='d-flex flex-wrap gap-3'>
              {/* Skipped ASINs Card */}
              <Card bg='info' text='white' className='flex-fill'>
                <Card.Body>
                  <Card.Text style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                    {summary.skippedAsins > 0 ? summary.skippedAsins : 0}
                  </Card.Text>
                  <Card.Title>Skipped ASINs</Card.Title>
                </Card.Body>
                <Card.Footer>
                  Out of <b>{summary.totalAsins}</b>
                </Card.Footer>
              </Card>

              {/* Total ASINs Processed Card */}
              <Card bg='primary' text='white' className='flex-fill'>
                <Card.Body>
                  <Card.Text style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                    {summary.totalAsinsChecked}
                  </Card.Text>
                  <Card.Title>Total ASINs Processed</Card.Title>
                </Card.Body>
                <Card.Footer>
                  Out of <b>{summary.totalAsins}</b>
                </Card.Footer>
              </Card>
              {/* Failed ASINs Card */}
              <Card bg='warning' text='white' className='flex-fill'>
                <Card.Body>
                  <Card.Text style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                    {summary.failedAsins}
                  </Card.Text>
                  <Card.Title>Failed ASINs</Card.Title>
                </Card.Body>
                <Card.Footer>
                  Out of <b>{summary.totalAsinsChecked}</b>
                </Card.Footer>
              </Card>
              {/* ASINs with MAP Violations Card */}
              <Card bg='danger' text='white' className='flex-fill'>
                <Card.Body>
                  <Card.Text style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                    {summary.asinsWithViolations}
                  </Card.Text>
                  <Card.Title>ASINs with MAP Violations</Card.Title>
                </Card.Body>
              </Card>
              {/* Unique Sellers Violating MAP Card */}
              <Card bg='danger' text='white' className='flex-fill'>
                <Card.Body>
                  <Card.Text style={{fontSize: '1.5rem', fontWeight: 'bold'}}>
                    {summary.uniqueViolatingSellers}
                  </Card.Text>
                  <Card.Title>Unique Sellers Violating MAP</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </div>
        )}

        {/* Violations Table */}
        {Object.keys(violations).length > 0 && summary && (
          <div className='mb-8 mt-8'>
            <Row className='mb-3'>
              <Col>
                <h3>MAP Violations</h3>
              </Col>
              <Col className='text-end'>
                <Button variant='success' className='me-2' onClick={exportViolationsCSV}>
                  Export Violations CSV
                </Button>
              </Col>
            </Row>
            <div className='violations-table-container'>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>ASIN</th>
                    <th className='product-title-td'>Product Name</th>
                    <th>MAP Price</th>
                    <th>Lowest Price</th>
                    <th>Price Difference</th>
                    <th className='violating-sellers'>Violating Sellers</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.values(violations).map((violation) => (
                    <tr key={violation.asin}>
                      <td>{violation.asin}</td>
                      <td>{violation.productName}</td>
                      <td>
                        ${violation.mapPrice !== undefined ? violation.mapPrice.toFixed(2) : 'N/A'}
                      </td>
                      <td>
                        $
                        {violation.lowestPrice !== undefined
                          ? violation.lowestPrice.toFixed(2)
                          : 'N/A'}
                      </td>
                      <td>
                        $
                        {violation.priceDifference !== undefined
                          ? violation.priceDifference.toFixed(2)
                          : 'N/A'}
                      </td>
                      <td>
                        <Table striped bordered hover responsive size='sm' className='mb-0'>
                          <thead>
                            <tr>
                              <th>Seller Name</th>
                              <th>Price</th>
                              <th>Seller URL</th>
                            </tr>
                          </thead>
                          <tbody>
                            {violation.violatingSellerInfo.map((seller, index) => (
                              <tr key={index}>
                                <td>{seller.sellerName}</td>
                                <td>
                                  ${seller.price !== undefined ? seller.price.toFixed(2) : 'N/A'}
                                </td>
                                <td>
                                  <a
                                    href={seller.sellerUrl}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    View Seller
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* New Section: Products without MAP Violations */}
        {nonViolations.length > 0 && summary && (
          <div className='mb-4 mt-8 '>
            <Row className='mb-6 mt-6'>
              <Col>
                <h3>Products without MAP Violations</h3>
              </Col>
              <Col className='text-end'>
                <Button variant='success' className='me-2' onClick={exportNonViolationsCSV}>
                  Export Non-Violations CSV
                </Button>
              </Col>
            </Row>
            <div className='non-violations-table-container violations-table-container'>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>ASIN</th>
                    <th className='product-title-td'>Product Name</th>
                    <th>MAP Price</th>
                    <th>Lowest Price</th>
                    <th className='violating-sellers'> Sellers</th>
                  </tr>
                </thead>
                <tbody>
                  {nonViolations.map((nonViolation, index) => (
                    <tr key={`${nonViolation.asin}-${index}`}>
                      <td>{nonViolation.asin}</td>
                      <td>{nonViolation.productName}</td>
                      <td>${nonViolation.mapPrice.toFixed(2)}</td>
                      <td>${nonViolation.lowestPrice.toFixed(2)}</td>
                      <td>
                        <Table striped bordered hover responsive size='sm' className='mb-0'>
                          <thead>
                            <tr>
                              <th>Seller Name</th>
                              <th>Price</th>
                              <th>Seller URL</th>
                            </tr>
                          </thead>
                          <tbody>
                            {nonViolation.sellers.map((seller, index) => (
                              <tr key={index}>
                                <td>{seller.sellerName}</td>
                                <td>
                                  ${seller.price !== undefined ? seller.price.toFixed(2) : 'N/A'}
                                </td>
                                <td>
                                  <a
                                    href={seller.sellerUrl}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                  >
                                    View Seller
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        )}

        {/* ASIN Input and Action Buttons */}
        <div className='card card-flush mb-4'>
          <div className='card-header'>
            <h2 className='card-title'>ASIN Selection</h2>
          </div>
          <div className='card-body'>
            <Row>
              <Col md={8}>
                <Form.Group controlId='asinInput'>
                  <Form.Label>Enter ASINs (one per line):</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={5}
                    value={asinInput}
                    onChange={handleAsinInputChange}
                  />
                </Form.Group>
              </Col>
              <Col md={12} className='d-flex flex-column mt-3'>
                <div className='d-flex p-2 flex-gap-2'>
                  <Button
                    variant='primary'
                    className='mb-2'
                    onClick={populateAsinInput}
                    disabled={selectedAsins.length === 0}
                  >
                    Populate ASINs from Selection
                  </Button>
                  <Button
                    variant='success'
                    className='mb-2 ms-2'
                    onClick={handleProcessViolations}
                    disabled={selectedAsins.length === 0}
                  >
                    {Object.keys(violations).length === 0 && summary === null
                      ? 'Process MAP Violations'
                      : 'Process Again'}
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  )
}

export const AmazonMAPViolationsWrapper: FC = () => {
  return (
    <>
      <PageTitle>Amazon MAP Violations</PageTitle>
      <AmazonMAPViolationsPage />
    </>
  )
}

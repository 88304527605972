import clsx from 'clsx'
import {KTIcon, toAbsoluteUrl} from '../../../helpers'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useLayout} from '../../core'
import {useAuth} from '../../../../app/modules/auth'

import lvLogo from '../../../assets/images/lv_logo.png'
import celineLogo from '../../../assets/images/celine_logo.png'
import diorLogo from '../../../assets/images/dior_logo.png'
import givenchyLogo from '../../../assets/images/givenchy_logo.png'
import loeweLogo from '../../../assets/images/loewe_logo.png'
import fendiLogo from '../../../assets/images/fendi_logo.png'

const itemClass = 'ms-1 ms-md-4'
const btnClass =
  'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px'
const userAvatarClass = 'symbol-35px'
const btnIconClass = 'fs-2'

const imageStyle = {
  maxWidth: '100px', // Adjust as needed
  maxHeight: '40px', // Adjust to fit within your navbar
  display: 'block', // To center images in their container
  margin: 'auto', // For horizontal centering
}

const LogoCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6, // Adjust the number of logos shown at once
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Speed of scroll (2000ms = 2 seconds)
    cssEase: 'linear',
  }

  return <></>
}

const Navbar = () => {
  const {currentUser, logout} = useAuth()
  const {config} = useLayout()
  return (
    <div className='app-navbar flex-shrink-0'>
      <div></div>
      <div className={clsx('app-navbar-item', itemClass)}>
        <div
          style={{paddingRight: '15px', paddingTop: '10px', height: '100%'}}
          className=' d-flex align-items-center'
        >
          {currentUser?.decoded?.vendorName === 'Designer Eyes' ? (
            <h3>DE Dashboard</h3>
          ) : currentUser?.decoded?.role === 'vendor' || currentUser?.decoded?.role === 'admin' ? (
            <img
              src={`/media/logos/${currentUser?.decoded?.vendorName}-Logo.png`}
              alt='{currentUser?.decoded?.vendorName}'
              style={{height: '80%', objectFit: 'contain', maxWidth: '200px'}}
            />
          ) : (
            <h1>{currentUser?.decoded?.vendorName}</h1>
          )}
        </div>

        {/* <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')} /> */}
      </div>

      <div className={clsx('app-navbar-item float-right', itemClass)}>
        <div
          className={clsx('cursor-pointer symbol mx-5 float-right', userAvatarClass)}
          data-kt-menu-trigger="{default: 'click'}"
          data-kt-menu-attach='parent'
          data-kt-menu-placement='bottom-end'
        >
          <i className='bi bi-person-fill'></i>
        </div>
        <HeaderUserMenu />
      </div>
    </div>
  )
}

export {Navbar}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useRef, useEffect} from 'react'
import {PageTitle} from '../../../../_designereyes/layout/core'
import axios from 'axios'
import {Dropdown} from 'primereact/dropdown'
import {Button} from 'primereact/button'
import {MultiSelect} from 'primereact/multiselect'
import {Toast} from 'primereact/toast'
import {SplitButton} from 'primereact/splitbutton'
import {DataTable, DataTableStateEvent} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {Card} from 'primereact/card'
import {InputTextarea} from 'primereact/inputtextarea'
import {Checkbox} from 'primereact/checkbox'
import {InputNumber} from 'primereact/inputnumber'
import {Dialog} from 'primereact/dialog'
import 'primereact/resources/themes/mdc-dark-deeppurple/theme.css'
import 'primereact/resources/primereact.min.css'
import {API_URL} from '../../../modules/auth/core/_requests'
import {SortOrder, FilterMatchMode} from 'primereact/api'
import * as XLSX from 'xlsx'
import './ProductTableWrapper.css'

// IMPORTANT: Make sure you import your ProductDetailsModal component
import {ProductDetailsModal} from './ProductDetailsModal'

interface FieldOption {
  label: string
  value: string
  description?: string
  width?: string
  sortable?: boolean
}

const ProductTablePage: FC = () => {
  const toast = useRef<Toast>(null)

  // Numeric fields for your custom numeric filter
  // Includes the new inventory columns so they are treated as numeric
  const numericFields = [
    'fifo_avg_cost',
    'fifo_cost',
    'weighted_avg_cost',
    'total_inventory_cost',
    'direct_cost',
    'total_cost',
    'inventory',
    'map_price',
    'msrp',
    'wholesale_price',
    'min_price',
    'final_min_price',
    'margin_amount',
    'referral_fee',
    'storage_fee',
    'pick_and_pack_fee',
    'lens_width',
    'bridge_width',
    'temple_length',
    // New inventory columns as numeric
    'stores_inventory',
    'de_main_bins_inventory',
    'ecomm_bins_inventory',
    'r001_inventory',
    'r002_inventory',
    'r003_inventory',
    'r004_inventory',
    'r005_inventory',
    'r006_inventory',
    'r008_inventory',
    'r009_inventory',
    'r010_inventory',
    'r011_inventory',
    'r014_inventory',
    'ecz_inventory',
    'vcs_inventory',
    'total_fba_inventory',
  ]

  // Advanced filter (menu) initial config
  const initialFilters: any = {
    global: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    upc: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    sku_1: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    sku_2: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    sku_3: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    asin: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    model: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    style_name: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    size_code: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    color_code: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    category: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    product_type: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    collection: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    vendor_name: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    brand: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    brand_code: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    color: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    gender: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    size: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    name: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    long_description: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    lens_width: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    bridge_width: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    temple_length: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    frame_color: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    frame_material: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    lens_color: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    lens_color_code: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    lens_material: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    shape: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    polarized: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    rxable: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    fifo_avg_cost: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    weighted_avg_cost: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    fifo_cost: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    total_inventory_cost: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    direct_cost: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    total_cost: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    inventory: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    map_price: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    msrp: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    wholesale_price: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    min_price: {operator: 'and', constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}]},
    final_min_price: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    margin_amount: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    referral_fee: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    storage_fee: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    pick_and_pack_fee: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    image_url1: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    image_url2: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    image_url3: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },

    // New inventory columns
    stores_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    de_main_bins_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    ecomm_bins_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r001_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r002_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r003_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r004_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r005_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r006_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r008_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r009_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r010_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r011_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    r014_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    ecz_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    vcs_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
    total_fba_inventory: {
      operator: 'and',
      constraints: [{value: null, matchMode: FilterMatchMode.CONTAINS}],
    },
  }

  const [filters, setFilters] = useState<any>(initialFilters)
  const [filtersChanged, setFiltersChanged] = useState(false)

  // For brand/vendor/specific
  const searchCriteriaOptions = [
    {label: 'UPC', value: 'UPC'},
    {label: 'SKU 1(NAV)', value: 'SKU 1'},
    {label: 'SKU 2(EC)', value: 'SKU 2'},
    {label: 'SKU 3(VSC)', value: 'SKU 3'},
    {label: 'ASIN', value: 'ASIN'},
  ]

  const brandOptions = [
    'ARNETTE',
    'BVLGARI',
    'BURBERRY',
    'CHANEL',
    'COACH',
    'COSTA',
    'DIOR',
    'DOLCE & GABBANA',
    'DRAGON',
    'EMPORIO ARMANI',
    'GIORGIO ARMANI',
    'GUCCI',
    'KATE SPADE',
    'MICHAEL KORS',
    'MYKITA',
    'OAKLEY',
    'OLIVER PEOPLES',
    'PERSOL',
    'PRADA',
    'RALPH',
    'RAY BAN',
    'TIFFANY',
    'TOM FORD',
    'VOGUE',
    'VERSACE',
  ].map((b) => ({label: b, value: b}))

  const vendorOptions = [
    'CHROME HEARTS',
    'DITA',
    'KERING EYEWEAR USA INC',
    'LUXOTTICA GROUP',
    'MARCHON',
    'MARCOLIN USA',
    'MYKITA',
    'SAFILO',
    'SWAROVSKI',
    'THELIOS U.S.A EYEWEAR CORP.',
  ].map((v) => ({label: v, value: v}))

  // Master fields
  const masterFields: FieldOption[] = [
    {label: 'UPC', value: 'upc', width: '100px', sortable: true},
    {label: 'SKU 1(NAV)', value: 'sku_1', width: '90px', sortable: true},
    {label: 'SKU 2(EC)', value: 'sku_2', width: '120px', sortable: true},
    {label: 'SKU 3(VSC)', value: 'sku_3', width: '120px', sortable: true},
    {label: 'ASIN', value: 'asin', width: '120px', sortable: true},
    {label: 'Model', value: 'model', width: '130px', sortable: true},
    {label: 'Style Name', value: 'style_name', width: '130px', sortable: true},
    {label: 'Size Code', value: 'size_code', width: '100px', sortable: true},
    {label: 'Color Code', value: 'color_code', width: '120px', sortable: true},
    {label: 'Category', value: 'category', width: '150px', sortable: true},
    {label: 'Product Type', value: 'product_type', width: '150px', sortable: true},
    {label: 'Collection', value: 'collection', width: '150px', sortable: true},
    {label: 'Vendor', value: 'vendor_name', width: '150px', sortable: true},
    {label: 'Brand', value: 'brand', width: '150px', sortable: true},
    {label: 'Brand Code', value: 'brand_code', width: '130px', sortable: true},
    {label: 'Color', value: 'color', width: '120px', sortable: true},
    {label: 'Gender', value: 'gender', width: '100px', sortable: true},
    {label: 'Size', value: 'size', width: '100px', sortable: true},
    {label: 'Name', value: 'name', width: '100px', sortable: true},
    {label: 'Description', value: 'long_description', width: '250px', sortable: false},
  ]

  // Category fields
  const categorySpecificFields: FieldOption[] = [
    {label: 'Lens Width', value: 'lens_width', width: '100px', sortable: true},
    {label: 'Bridge Width', value: 'bridge_width', width: '100px', sortable: true},
    {label: 'Temple Length', value: 'temple_length', width: '100px', sortable: true},
    {label: 'Frame Color', value: 'frame_color', width: '120px', sortable: true},
    {label: 'Frame Material', value: 'frame_material', width: '150px', sortable: true},
    {label: 'Lens Color', value: 'lens_color', width: '120px', sortable: true},
    {label: 'Lens Color Code', value: 'lens_color_code', width: '130px', sortable: true},
    {label: 'Lens Material', value: 'lens_material', width: '150px', sortable: true},
    {label: 'Lens Shape', value: 'shape', width: '120px', sortable: true},
    {label: 'Polarized', value: 'polarized', width: '100px', sortable: true},
    {label: 'Rxable', value: 'rxable', width: '100px', sortable: true},
  ]

  // Images
  const imagesFields: FieldOption[] = [
    {label: 'Image URL 1', value: 'image_url1', width: '100px', sortable: false},
    {label: 'Image URL 2', value: 'image_url2', width: '100px', sortable: false},
    {label: 'Image URL 3', value: 'image_url3', width: '100px', sortable: false},
  ]

  // Financial
  const financialFields: FieldOption[] = [
    {label: 'FIFO Avg Cost', value: 'fifo_avg_cost', width: '130px', sortable: true},
    {label: 'Weighted Avg Cost', value: 'weighted_avg_cost', width: '150px', sortable: true},
    {label: 'FIFO Cost', value: 'fifo_cost', width: '100px', sortable: true},
    {label: 'Total Inventory Cost', value: 'total_inventory_cost', width: '150px', sortable: true},
    {label: 'Direct Cost', value: 'direct_cost', width: '120px', sortable: true},
    {label: 'Total Cost', value: 'total_cost', width: '120px', sortable: true},
    {label: 'Inventory', value: 'inventory', width: '100px', sortable: true},
    {label: 'Map Price', value: 'map_price', width: '120px', sortable: true},
    {label: 'MSRP Price', value: 'msrp', width: '120px', sortable: true},
    {label: 'Wholesale Price', value: 'wholesale_price', width: '150px', sortable: true},
    {label: 'Minimum AMZ Price', value: 'min_price', width: '130px', sortable: true},
    {
      label: 'Minimum AMZ Price(MAP override)',
      value: 'final_min_price',
      width: '180px',
      sortable: true,
    },
    {label: 'Minimum Profit Margin', value: 'margin_amount', width: '150px', sortable: true},
    {label: 'Minimum Referral Fee', value: 'referral_fee', width: '150px', sortable: true},
    {label: 'Amazon Storage Fee', value: 'storage_fee', width: '160px', sortable: true},
    {label: 'Pick and Pack Fee', value: 'pick_and_pack_fee', width: '160px', sortable: true},
  ]

  // NEW Inventory Fields
  const inventoryFields: FieldOption[] = [
    {label: 'All Store Inventory', value: 'stores_inventory', width: '140px', sortable: true},
    {
      label: 'Pickable Warehouse Inventory',
      value: 'de_main_bins_inventory',
      width: '160px',
      sortable: true,
    },
    {label: 'Ecomm', value: 'ecomm_bins_inventory', width: '50px', sortable: true},
    {label: 'R001 - POV Sawgrass', value: 'r001_inventory', width: '50px', sortable: true},
    {label: 'R002 - Just One Look', value: 'r002_inventory', width: '50px', sortable: true},
    {label: 'R003 - Eyes on Lincoln', value: 'r003_inventory', width: '50px', sortable: true},
    {label: 'R004 - PV2 Dolphin', value: 'r004_inventory', width: '50px', sortable: true},
    {label: 'R005 - DE Mall of San Juan', value: 'r005_inventory', width: '50px', sortable: true},
    {label: 'R006 - DE Brickell', value: 'r006_inventory', width: '50px', sortable: true},
    {label: 'R008 - DE Florida Mall', value: 'r008_inventory', width: '50px', sortable: true},
    {label: 'R009 - Aventura', value: 'r009_inventory', width: '50px', sortable: true},
    {label: 'R010 - PV3 iDrive', value: 'r010_inventory', width: '50px', sortable: true},
    {label: 'R011 - PV4 Vineland', value: 'r011_inventory', width: '50px', sortable: true},
    {label: 'R014 - DE Plaza Las Americ=', value: 'r014_inventory', width: '50px', sortable: true},
    {label: 'ECZ FBA Inventory', value: 'ecz_inventory', width: '50px', sortable: true},
    {label: 'VSC FBA Inventory', value: 'vcs_inventory', width: '50px', sortable: true},
    {label: 'Total FBA Inventory', value: 'total_fba_inventory', width: '50px', sortable: true},
  ]

  // Combine all
  const allFields = [
    ...masterFields,
    ...categorySpecificFields,
    ...imagesFields,
    ...financialFields,
    ...inventoryFields,
  ]

  // Group for MultiSelect
  const columnGroups = [
    {
      label: 'Master Fields',
      items: masterFields.map((f) => ({label: f.label, value: f.value})),
    },
    {
      label: 'Category Specific Fields',
      items: categorySpecificFields.map((f) => ({label: f.label, value: f.value})),
    },
    {
      label: 'Images Fields',
      items: imagesFields.map((f) => ({label: f.label, value: f.value})),
    },
    {
      label: 'Financial Fields',
      items: financialFields.map((f) => ({label: f.label, value: f.value})),
    },
    {
      label: 'Inventory Fields',
      items: inventoryFields.map((f) => ({label: f.label, value: f.value})),
    },
  ]

  // We remove image_url1 from selectableColumns to avoid duplication
  const selectableColumns = allFields.filter((field) => field.value !== 'image_url1')

  // Sort user-chosen columns
  const sortSelectedColumns = (columns: string[]): string[] => {
    return allFields.filter((field) => columns.includes(field.value)).map((field) => field.value)
  }

  // Load or default columns
  const savedColumns = localStorage.getItem('selectedColumns')
  const defaultSelectedColumns = savedColumns
    ? sortSelectedColumns(JSON.parse(savedColumns))
    : sortSelectedColumns(['upc', 'name', 'brand', 'category', 'inventory'])

  // States for the search mode
  const [searchMode, setSearchMode] = useState<'all' | 'brand' | 'vendor' | 'specific'>('all')
  const [searchCriterion, setSearchCriterion] = useState<string>('UPC')
  const [selectedBrand, setSelectedBrand] = useState<string | null>(null)
  const [selectedVendor, setSelectedVendor] = useState<string | null>(null)
  const [productValues, setProductValues] = useState<string>('')
  const [selectedColumns, setSelectedColumns] = useState<string[]>(defaultSelectedColumns)
  const [loading, setLoading] = useState(false)
  const [productsData, setProductsData] = useState<any[]>([])
  const [productsFound, setProductsFound] = useState<number>(0)
  const [productsSearched, setProductsSearched] = useState<number>(0)
  const [first, setFirst] = useState(0)
  const [rows] = useState(50)
  const [totalRecords, setTotalRecords] = useState(0)
  const [instockAmazon, setInstockAmazon] = useState(false)
  const [hasImages, setHasImages] = useState(false)
  const [sortField, setSortField] = useState<string | undefined>(undefined)
  const [sortOrder, setSortOrder] = useState<SortOrder | undefined>(undefined)

  // Named presets
  const [presetName, setPresetName] = useState('')
  const [namedPresets, setNamedPresets] = useState<{[key: string]: string[]}>(() =>
    JSON.parse(localStorage.getItem('namedPresets') || '{}')
  )
  const [presetDialogVisible, setPresetDialogVisible] = useState(false)

  // Save namedPresets
  useEffect(() => {
    localStorage.setItem('namedPresets', JSON.stringify(namedPresets))
  }, [namedPresets])

  // On mount
  useEffect(() => {
    loadData(0)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // For the ProductDetailsModal
  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [detailsVisible, setDetailsVisible] = useState(false)

  // When user changes columns
  const handleColumnChange = (cols: string[]) => {
    const sortedCols = sortSelectedColumns(cols)
    setSelectedColumns(sortedCols)
    localStorage.setItem('selectedColumns', JSON.stringify(sortedCols))
  }

  // Numeric filter template
  const balanceFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onValueChange={(e) => options.filterCallback(e.value, options.index)}
        placeholder='Enter a value'
        style={{width: '100%'}}
      />
    )
  }

  /*************************************************************/
  /** fetchFullProductByUPC => fetches all columns for the modal
   *  (including the new inventory fields if you want)
   *************************************************************/
  const fetchFullProductByUPC = async (upc: string) => {
    try {
      const productList = [upc.trim()]

      // We'll request ALL possible columns for the modal:
      const masterFs = [
        'upc',
        'sku_1',
        'sku_2',
        'sku_3',
        'asin',
        'model',
        'style_name',
        'size_code',
        'color_code',
        'category',
        'product_type',
        'collection',
        'vendor_name',
        'brand',
        'brand_code',
        'color',
        'gender',
        'size',
        'name',
        'long_description',
      ]
      const categoryFs = [
        'lens_width',
        'bridge_width',
        'temple_length',
        'frame_color',
        'frame_material',
        'lens_color',
        'lens_color_code',
        'lens_material',
        'shape',
        'polarized',
        'rxable',
      ]
      const imagesFs = ['image_url1', 'image_url2', 'image_url3']
      const financialFs = [
        'fifo_avg_cost',
        'weighted_avg_cost',
        'fifo_cost',
        'total_inventory_cost',
        'direct_cost',
        'total_cost',
        'inventory',
        'map_price',
        'msrp',
        'wholesale_price',
        'min_price',
        'final_min_price',
        'margin_amount',
        'referral_fee',
        'storage_fee',
        'pick_and_pack_fee',
      ]
      const inventoryFs = [
        'stores_inventory',
        'de_main_bins_inventory',
        'ecomm_bins_inventory',
        'r001_inventory',
        'r002_inventory',
        'r003_inventory',
        'r004_inventory',
        'r005_inventory',
        'r006_inventory',
        'r008_inventory',
        'r009_inventory',
        'r010_inventory',
        'r011_inventory',
        'r014_inventory',
        'ecz_inventory',
        'vcs_inventory',
        'total_fba_inventory',
      ]

      const payload: any = {
        selectedFields: {
          master: masterFs,
          categorySpecific: categoryFs,
          financial: financialFs,
          images: imagesFs,
          inventory: inventoryFs,
        },
        limit: 1,
        offset: 0,
        searchMode: 'specific',
        searchCriterion: 'UPC',
        products: productList,
        filters: {},
      }

      const response = await axios.post(`${API_URL}/products?format=json`, payload)
      const data = response.data.data || []
      if (data.length === 1) {
        return data[0]
      }
      return null
    } catch (error) {
      console.error('Error fetching full product by UPC:', error)
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Unable to fetch full product details by UPC',
        life: 3000,
      })
      return null
    }
  }

  // Eye icon column => calls fetchFullProductByUPC
  const viewProductBodyTemplate = (rowData: any) => {
    return (
      <Button
        icon='fa fa-eye'
        className='p-button-text p-button-plain'
        onClick={async () => {
          if (!rowData.upc) {
            toast.current?.show({
              severity: 'warn',
              summary: 'No UPC',
              detail: 'Cannot fetch details without a UPC',
              life: 3000,
            })
            return
          }
          const fullData = await fetchFullProductByUPC(rowData.upc)
          if (fullData) {
            setSelectedProduct(fullData)
            setDetailsVisible(true)
          } else {
            toast.current?.show({
              severity: 'info',
              summary: 'Not Found',
              detail: `Could not find full data for UPC: ${rowData.upc}`,
              life: 3000,
            })
          }
        }}
        tooltip='View Product Details'
      />
    )
  }

  // Optional action column
  const actionBodyTemplate = (rowData: any) => {
    const handleDownloadImages = () => {
      console.log('Download Amazon Images:', rowData)
    }
    const items = [
      {
        label: 'Download Amazon Images',
        icon: 'fa fa-download',
        command: () => handleDownloadImages(),
      },
    ]
    return (
      <SplitButton
        label=''
        icon='fa fa-magnifying-glass'
        model={items}
        className='p-button-text'
        style={{padding: '0', border: 'none'}}
        menuStyle={{minWidth: '10rem'}}
      />
    )
  }

  // Main loadData
  const loadData = async (
    offset: number,
    sortFieldParam: string | undefined = sortField,
    sortOrderParam: SortOrder | undefined = sortOrder
  ) => {
    try {
      setLoading(true)
      const productList = productValues
        .split('\n')
        .map((v) => v.trim())
        .filter(Boolean)

      // figure out which fields are selected
      const masterFs = [
        'upc',
        'sku_1',
        'sku_2',
        'sku_3',
        'asin',
        'model',
        'style_name',
        'size_code',
        'color_code',
        'category',
        'product_type',
        'collection',
        'vendor_name',
        'brand',
        'brand_code',
        'color',
        'gender',
        'size',
        'name',
        'long_description',
      ]
      const categoryFs = [
        'lens_width',
        'bridge_width',
        'temple_length',
        'frame_color',
        'frame_material',
        'lens_color',
        'lens_color_code',
        'lens_material',
        'shape',
        'polarized',
        'rxable',
      ]
      const imagesFs = ['image_url1', 'image_url2', 'image_url3']
      const financialFs = [
        'fifo_avg_cost',
        'weighted_avg_cost',
        'fifo_cost',
        'total_inventory_cost',
        'direct_cost',
        'total_cost',
        'inventory',
        'map_price',
        'msrp',
        'wholesale_price',
        'min_price',
        'final_min_price',
        'margin_amount',
        'referral_fee',
        'storage_fee',
        'pick_and_pack_fee',
      ]
      // new inventory
      const inventoryFs = [
        'stores_inventory',
        'de_main_bins_inventory',
        'ecomm_bins_inventory',
        'r001_inventory',
        'r002_inventory',
        'r003_inventory',
        'r004_inventory',
        'r005_inventory',
        'r006_inventory',
        'r008_inventory',
        'r009_inventory',
        'r010_inventory',
        'r011_inventory',
        'r014_inventory',
        'ecz_inventory',
        'vcs_inventory',
        'total_fba_inventory',
      ]

      // user selected
      const masterSelected = masterFs.filter((f) => selectedColumns.includes(f))
      const categorySelected = categoryFs.filter((f) => selectedColumns.includes(f))
      const financialSelected = financialFs.filter((f) => selectedColumns.includes(f))
      const imagesSelected = imagesFs.filter((f) => selectedColumns.includes(f))
      const inventorySelected = inventoryFs.filter((f) => selectedColumns.includes(f))

      // ensure image_url1 if not present
      if (!imagesSelected.includes('image_url1')) {
        imagesSelected.push('image_url1')
      }

      const payload: any = {
        selectedFields: {
          master: masterSelected,
          categorySpecific: categorySelected,
          financial: financialSelected,
          images: imagesSelected,
          inventory: inventorySelected, // NEW
        },
        limit: rows,
        offset,
        instock_amazon: instockAmazon,
        has_images: hasImages,
        sortField: sortFieldParam || undefined,
        sortOrder: sortOrderParam || undefined,
        filters,
      }

      if (searchMode === 'brand' && selectedBrand) {
        payload.brand = selectedBrand
      } else if (searchMode === 'vendor' && selectedVendor) {
        payload.vendor = selectedVendor
      } else if (searchMode === 'specific' && productList.length > 0) {
        payload.searchCriterion = searchCriterion
        payload.products = productList
      }

      const response = await axios.post(`${API_URL}/products?format=json`, payload)
      setLoading(false)

      const data = response.data.data || []
      const found = response.data.productsFound
      const searched = response.data.productsSearched

      setProductsData(data)
      setProductsFound(found)
      setProductsSearched(searched)
      setTotalRecords(found)
      setFiltersChanged(false)

      if (found === 0) {
        toast.current?.show({
          severity: 'info',
          summary: 'No Items Found',
          detail: 'No items found.',
          life: 3000,
        })
      }
    } catch (error: any) {
      setLoading(false)
      console.error('Error fetching data:', error)
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while fetching data',
        life: 3000,
      })
    }
  }

  const handleSearch = async () => {
    setFirst(0)
    await loadData(0, sortField, sortOrder)
  }

  const onPage = async (e: DataTableStateEvent) => {
    setFirst(e.first ?? 0)
    await loadData(e.first ?? 0, sortField, sortOrder)
  }

  const onSort = async (e: DataTableStateEvent) => {
    const newSortField = e.sortField || undefined
    const newSortOrder = e.sortOrder || undefined

    setSortField(newSortField)
    setSortOrder(newSortOrder)
    await loadData(0, newSortField, newSortOrder)
  }

  // Compose columns to render in DataTable
  // NOTE: We now do style={{ width: field.width || '150px' }} so it uses field.width as exact width
  const columnsToRender = allFields
    .filter((field) => selectedColumns.includes(field.value))
    .map((field) => {
      const isNumeric = numericFields.includes(field.value)
      return (
        <Column
          key={field.value}
          field={field.value}
          header={field.label}
          style={{
            width: field.width || '120px',
            maxHeight: '1.7em',
            borderTop: '1px solid #25253e',
          }}
          sortable={field.sortable ?? false}
          filter
          filterPlaceholder='Search by name'
          showFilterMenuOptions
          showFilterOperator
          showFilterMatchModes
          {...(isNumeric ? {dataType: 'numeric', filterElement: balanceFilterTemplate} : {})}
        />
      )
    })

  // Named Preset logic
  const handleSaveNewPreset = () => {
    const trimmedName = presetName.trim()
    if (!trimmedName) {
      toast.current?.show({
        severity: 'warn',
        summary: 'Invalid Name',
        detail: 'Please enter a valid preset name.',
        life: 2000,
      })
      return
    }
    setNamedPresets((prev) => {
      return {...prev, [trimmedName]: selectedColumns}
    })
    setPresetName('')
    toast.current?.show({
      severity: 'success',
      summary: 'Preset Saved',
      detail: `Preset "${trimmedName}" saved successfully.`,
      life: 2000,
    })
  }

  const handleLoadNamedPreset = (key: string) => {
    const columns = namedPresets[key]
    if (!columns || columns.length === 0) {
      toast.current?.show({
        severity: 'warn',
        summary: 'No Columns',
        detail: `Preset "${key}" has no columns saved.`,
        life: 2000,
      })
      return
    }
    setSelectedColumns(columns)
    localStorage.setItem('selectedColumns', JSON.stringify(columns))
    toast.current?.show({
      severity: 'success',
      summary: 'Preset Loaded',
      detail: `Preset "${key}" loaded successfully.`,
      life: 2000,
    })
  }

  const handleDeleteNamedPreset = (key: string) => {
    setNamedPresets((prev) => {
      const updated = {...prev}
      delete updated[key]
      return updated
    })
    toast.current?.show({
      severity: 'warn',
      summary: 'Preset Deleted',
      detail: `Preset "${key}" has been deleted.`,
      life: 2000,
    })
  }

  // Download Excel
  const loadAllDataForExcel = async () => {
    if (productsFound === 0) {
      toast.current?.show({
        severity: 'warn',
        summary: 'No Data',
        detail: 'No products to export.',
        life: 3000,
      })
      return []
    }

    const productList = productValues
      .split('\n')
      .map((v) => v.trim())
      .filter(Boolean)

    const masterFs = [
      'upc',
      'sku_1',
      'sku_2',
      'sku_3',
      'asin',
      'model',
      'style_name',
      'size_code',
      'color_code',
      'category',
      'product_type',
      'collection',
      'vendor_name',
      'brand',
      'brand_code',
      'color',
      'gender',
      'size',
      'name',
      'long_description',
    ]
    const categoryFs = [
      'lens_width',
      'bridge_width',
      'temple_length',
      'frame_color',
      'frame_material',
      'lens_color',
      'lens_color_code',
      'lens_material',
      'shape',
      'polarized',
      'rxable',
    ]
    const imagesFs = ['image_url1', 'image_url2', 'image_url3']
    const financialFs = [
      'fifo_avg_cost',
      'weighted_avg_cost',
      'fifo_cost',
      'total_inventory_cost',
      'direct_cost',
      'total_cost',
      'inventory',
      'map_price',
      'msrp',
      'wholesale_price',
      'min_price',
      'final_min_price',
      'margin_amount',
      'referral_fee',
      'storage_fee',
      'pick_and_pack_fee',
    ]
    const inventoryFs = [
      'stores_inventory',
      'de_main_bins_inventory',
      'ecomm_bins_inventory',
      'r001_inventory',
      'r002_inventory',
      'r003_inventory',
      'r004_inventory',
      'r005_inventory',
      'r006_inventory',
      'r008_inventory',
      'r009_inventory',
      'r010_inventory',
      'r011_inventory',
      'r014_inventory',
      'ecz_inventory',
      'vcs_inventory',
      'total_fba_inventory',
    ]

    const masterSelected = masterFs.filter((f) => selectedColumns.includes(f))
    const categorySelected = categoryFs.filter((f) => selectedColumns.includes(f))
    const financialSelected = financialFs.filter((f) => selectedColumns.includes(f))
    const imagesSelected = imagesFs.filter((f) => selectedColumns.includes(f))
    const inventorySelected = inventoryFs.filter((f) => selectedColumns.includes(f))

    // ensure image_url1
    if (!imagesSelected.includes('image_url1')) {
      imagesSelected.push('image_url1')
    }

    const payload: any = {
      selectedFields: {
        master: masterSelected,
        categorySpecific: categorySelected,
        financial: financialSelected,
        images: imagesSelected,
        inventory: inventorySelected,
      },
      limit: productsFound,
      offset: 0,
      instock_amazon: instockAmazon,
      has_images: hasImages,
      sortField: sortField || undefined,
      sortOrder: sortOrder || undefined,
      filters,
    }

    if (searchMode === 'brand' && selectedBrand) {
      payload.brand = selectedBrand
    } else if (searchMode === 'vendor' && selectedVendor) {
      payload.vendor = selectedVendor
    } else if (searchMode === 'specific' && productList.length > 0) {
      payload.searchCriterion = searchCriterion
      payload.products = productList
    }

    try {
      setLoading(true)
      const response = await axios.post(`${API_URL}/products?format=json`, payload)
      setLoading(false)

      const fullData = response.data.data || []
      return fullData
    } catch (error: any) {
      setLoading(false)
      console.error('Error fetching full data:', error)
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'An error occurred while fetching full data',
        life: 3000,
      })
      return []
    }
  }

  const downloadExcel = async () => {
    if (productsFound === 0) {
      toast.current?.show({
        severity: 'warn',
        summary: 'No Data',
        detail: 'No products to export.',
        life: 3000,
      })
      return
    }
    toast.current?.show({
      severity: 'info',
      summary: 'Download Excel',
      detail: `Fetching ${productsFound} products with ${selectedColumns.length} columns...`,
      life: 2000,
    })

    const fullData = await loadAllDataForExcel()
    if (fullData.length === 0) {
      return
    }

    toast.current?.show({
      severity: 'info',
      summary: 'Download Excel',
      detail: `Downloading ${fullData.length} products with ${selectedColumns.length} columns.`,
      life: 2000,
    })

    const exportedData = fullData.map((item) => {
      const row: any = {}
      selectedColumns.forEach((col) => {
        row[col] = item[col]
      })
      return row
    })

    const ws = XLSX.utils.json_to_sheet(exportedData)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Data')
    XLSX.writeFile(wb, 'export.xlsx')
  }

  // For the main "Search / Update Search" button
  const searchButtonLabel = filtersChanged ? 'Update Search' : 'Search'
  const searchButtonClass = filtersChanged ? 'btn btn-warning' : 'btn btn-primary'

  return (
    <div className='p-4' style={{background: 'transparent'}}>
      <Toast ref={toast} />

      {/* Search Section */}
      <Card className='mb-3 shadow-sm'>
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-md-8 mb-3'>
              <div className='row g-2 align-items-center'>
                <div className='col-auto d-flex align-items-center'>
                  <div className='form-check form-check-inline me-3'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='modeAll'
                      name='searchMode'
                      value='all'
                      checked={searchMode === 'all'}
                      onChange={() => {
                        setSearchMode('all')
                        setSelectedBrand(null)
                        setSelectedVendor(null)
                        setProductValues('')
                        setFiltersChanged(true)
                      }}
                    />
                    <label className='form-check-label' htmlFor='modeAll'>
                      All Products
                    </label>
                  </div>
                  <div className='form-check form-check-inline me-3'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='modeBrand'
                      name='searchMode'
                      value='brand'
                      checked={searchMode === 'brand'}
                      onChange={() => {
                        setSearchMode('brand')
                        setSelectedVendor(null)
                        setProductValues('')
                        setFiltersChanged(true)
                      }}
                    />
                    <label className='form-check-label' htmlFor='modeBrand'>
                      By Brand
                    </label>
                  </div>
                  <div className='form-check form-check-inline me-3'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='modeVendor'
                      name='searchMode'
                      value='vendor'
                      checked={searchMode === 'vendor'}
                      onChange={() => {
                        setSearchMode('vendor')
                        setSelectedBrand(null)
                        setProductValues('')
                        setFiltersChanged(true)
                      }}
                    />
                    <label className='form-check-label' htmlFor='modeVendor'>
                      By Vendor
                    </label>
                  </div>
                  <div className='form-check form-check-inline'>
                    <input
                      className='form-check-input'
                      type='radio'
                      id='modeSpecific'
                      name='searchMode'
                      value='specific'
                      checked={searchMode === 'specific'}
                      onChange={() => {
                        setSearchMode('specific')
                        setSelectedBrand(null)
                        setSelectedVendor(null)
                        setFiltersChanged(true)
                      }}
                    />
                    <label className='form-check-label' htmlFor='modeSpecific'>
                      By Specific Values
                    </label>
                  </div>
                </div>

                {searchMode === 'brand' && (
                  <div className='col-md-5'>
                    <Dropdown
                      value={selectedBrand}
                      options={brandOptions}
                      onChange={(e) => {
                        setSelectedBrand(e.value)
                        setFiltersChanged(true)
                      }}
                      placeholder='Select Brand'
                      className='p-column-filter w-100'
                      style={{minWidth: '200px'}}
                    />
                  </div>
                )}

                {searchMode === 'vendor' && (
                  <div className='col-md-5'>
                    <Dropdown
                      value={selectedVendor}
                      options={vendorOptions}
                      onChange={(e) => {
                        setSelectedVendor(e.value)
                        setFiltersChanged(true)
                      }}
                      placeholder='Select Vendor'
                      className='p-column-filter w-100'
                      style={{minWidth: '200px'}}
                    />
                  </div>
                )}

                {searchMode === 'specific' && (
                  <>
                    <div className='col-md-5'>
                      <Dropdown
                        value={searchCriterion}
                        options={searchCriteriaOptions}
                        onChange={(e) => {
                          setSearchCriterion(e.value)
                          setFiltersChanged(true)
                        }}
                        placeholder='Select Criterion'
                        className='p-column-filter w-100'
                        style={{minWidth: '200px'}}
                      />
                    </div>
                    <div className='col-md-7 mt-1'>
                      <label className='form-label'>Enter Values (one per line):</label>
                      <InputTextarea
                        rows={3}
                        className='form-control'
                        value={productValues}
                        onChange={(e) => {
                          setProductValues(e.target.value)
                          setFiltersChanged(true)
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='col-md-4 mb-3 d-flex align-items-center justify-content-end gap-3'>
              <div className='d-flex align-items-center gap-2'>
                <div className='form-check form-switch d-flex align-items-center'>
                  <Checkbox
                    inputId='instockAmazon'
                    checked={instockAmazon}
                    onChange={(e) => {
                      setInstockAmazon(e.checked ?? false)
                      setFiltersChanged(true)
                    }}
                  />
                  <label htmlFor='instockAmazon' className='form-check-label ms-2'>
                    In Stock
                  </label>
                </div>

                <div className='form-check form-switch d-flex align-items-center'>
                  <Checkbox
                    inputId='hasImages'
                    checked={hasImages}
                    onChange={(e) => {
                      setHasImages(e.checked ?? false)
                      setFiltersChanged(true)
                    }}
                  />
                  <label htmlFor='hasImages' className='form-check-label ms-2'>
                    Has Images
                  </label>
                </div>
              </div>

              <Button
                label={filtersChanged ? 'Update Search' : 'Search'}
                icon='fa fa-search'
                className={filtersChanged ? 'btn btn-warning' : 'btn btn-primary'}
                onClick={handleSearch}
              />
            </div>
          </div>
        </div>
      </Card>

      <Card className='mb-3 shadow-sm'>
        <div className='container-fluid'>
          <div className='row align-items-center'>
            <div className='col-md-8 mb-3 mb-md-0'>
              <div className='input-group'>
                <span className='input-group-text' id='columnsSelect'>
                  <i className='fa fa-columns'></i>
                </span>
                <MultiSelect
                  value={selectedColumns}
                  options={columnGroups}
                  optionGroupLabel='label'
                  optionGroupChildren='items'
                  filter
                  filterPlaceholder='Search columns...'
                  placeholder='Select Columns'
                  display='chip'
                  className='p-column-filter w-100'
                  panelStyle={{maxHeight: '600px', whiteSpace: 'normal'}}
                  style={{maxWidth: '800px', whiteSpace: 'normal'}}
                  onChange={(e) => handleColumnChange(e.value)}
                />
              </div>
            </div>

            <div className='col-md-4 d-flex justify-content-end gap-2'>
              <Button
                label='Manage Presets'
                icon='fa fa-cog'
                className='btn btn-info rounded-pill'
                onClick={() => setPresetDialogVisible(true)}
              />
              <Button
                label='Download Excel'
                icon='fa fa-file-excel'
                className='btn btn-success rounded-pill'
                onClick={downloadExcel}
              />
            </div>
          </div>
        </div>
      </Card>

      {/* Manage Presets Dialog */}
      <Dialog
        visible={presetDialogVisible}
        onHide={() => setPresetDialogVisible(false)}
        header='Manage Presets'
        style={{width: '400px'}}
      >
        <div className='mb-3'>
          <label className='form-label'>Preset Name</label>
          <input
            type='text'
            className='form-control'
            placeholder='Enter preset name...'
            value={presetName}
            onChange={(e) => setPresetName(e.target.value)}
          />
          <Button
            label='Save Preset'
            className='btn btn-outline-secondary mt-2'
            onClick={handleSaveNewPreset}
          />
        </div>

        {Object.keys(namedPresets).length > 0 && (
          <div style={{maxHeight: '250px', overflowY: 'auto'}}>
            <ul className='list-group'>
              {Object.keys(namedPresets).map((key) => (
                <li
                  key={key}
                  className='list-group-item d-flex align-items-center justify-content-between'
                  style={{background: '#111111'}}
                >
                  <span>{key}</span>
                  <div>
                    <Button
                      label='Load'
                      className='btn btn-secondary btn-sm me-2'
                      style={{background: 'green', color: 'white'}}
                      onClick={() => handleLoadNamedPreset(key)}
                    />
                    <Button
                      label='Delete'
                      style={{background: 'red', color: 'white'}}
                      className='btn btn-outline-danger btn-sm'
                      onClick={() => handleDeleteNamedPreset(key)}
                    />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </Dialog>

      {/* Info about how many products found */}
      {productsFound !== undefined && productsSearched !== undefined && productsFound > 0 && (
        <Card className='m-0 shadow-sm' style={{background: 'transparent'}}>
          <p className='m-0'>
            {searchMode === 'brand' && selectedBrand
              ? `Showing ${productsFound} products for brand "${selectedBrand}".`
              : searchMode === 'vendor' && selectedVendor
              ? `Showing ${productsFound} products for vendor "${selectedVendor}".`
              : searchMode === 'specific'
              ? `Found ${productsFound} of ${productsSearched} items.`
              : `Showing ${productsFound} products in total.`}
          </p>
        </Card>
      )}

      {/* Main DataTable */}
      <DataTable
        scrollable
        scrollHeight='700px'
        value={productsData}
        loading={loading}
        paginator
        rows={rows}
        first={first}
        totalRecords={totalRecords}
        lazy
        onPage={onPage}
        onSort={onSort}
        sortField={sortField}
        sortOrder={sortOrder}
        responsiveLayout='scroll'
        className='mt-1'
        style={{background: 'transparent'}}
        filterDisplay='menu'
        filters={filters}
        onFilter={(e) => {
          setFilters(e.filters)
          setFirst(0)
          setFiltersChanged(true)
        }}
      >
        {/* Eye icon column to open product details */}
        <Column header='' body={viewProductBodyTemplate} style={{width: '20px'}} />

        {/* Existing actions column (optional) */}
        {/* <Column key='actions' header='' body={actionBodyTemplate} style={{width: '20px'}} /> */}

        {/* The main image column */}
        <Column
          key='image_url1'
          field='image_url1'
          header='Image'
          style={{width: '80px', borderTop: '1px solid #25253e'}}
          body={(rowData) => {
            if (rowData.image_url1) {
              const hasProtocol =
                rowData.image_url1.startsWith('http://') ||
                rowData.image_url1.startsWith('https://')
              let imageUrl = ''

              if (hasProtocol) {
                imageUrl = rowData.image_url1
              } else if (rowData.image_url1.startsWith('/')) {
                imageUrl = `${window.location.origin}${rowData.image_url1}`
              } else {
                imageUrl = `https://${rowData.image_url1}`
              }

              return (
                <img
                  src={imageUrl}
                  alt='Product thumbnail'
                  style={{
                    width: '120px',
                    height: '60px',
                    objectFit: 'contain',
                    background: 'white',
                    borderRadius: '15px',
                  }}
                  onError={(e) => {
                    ;(e.currentTarget as HTMLImageElement).src = 'https://via.placeholder.com/50'
                  }}
                />
              )
            }
            return null
          }}
          sortable={false}
          filter
          filterPlaceholder='Search by name'
        />

        {/* Render all other columns chosen by user */}
        {columnsToRender}
      </DataTable>

      {/* The new ProductDetailsModal for viewing product details */}
      <ProductDetailsModal
        visible={detailsVisible}
        onHide={() => setDetailsVisible(false)}
        productData={selectedProduct}
      />
    </div>
  )
}

export const ProductTableWrapper: FC = () => {
  return (
    <>
      <PageTitle>Product Table</PageTitle>
      <div className='alert alert-danger' role='alert'>
        <p>
          This project is an experimental project. If you encounter any bugs, please report them to
          Lucas.
        </p>
      </div>
      <ProductTablePage />
    </>
  )
}

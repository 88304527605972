import React, {FC, useEffect, useState} from 'react'
import axios from 'axios'
import {API_URL} from '../../../modules/auth/core/_requests'
import {useParams, useNavigate} from 'react-router-dom'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import * as XLSX from 'xlsx'

export const BuyBoxReportDetailPage: FC = () => {
  const {reportId} = useParams()
  const navigate = useNavigate()

  const [report, setReport] = useState<any>(null)
  const [dataRows, setDataRows] = useState<any[]>([])
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(30)
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (reportId) {
      fetchReport(page, pageSize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportId, page, pageSize])

  const fetchReport = async (p: number, ps: number) => {
    if (!reportId) return
    setLoading(true)
    try {
      const url = `${API_URL}/api/buybox-reports/${reportId}?page=${p}&pageSize=${ps}`
      const resp = await axios.get(url)
      setReport(resp.data.report)
      setDataRows(resp.data.data || [])
      setTotal(resp.data.total || 0)
    } catch (err) {
      console.error('Failed to fetch report detail', err)
      setError('Failed to fetch report detail')
    }
    setLoading(false)
  }

  const onPageChange = (e: any) => {
    setPage(e.page + 1)
    setPageSize(e.rows)
  }

  const handleExportExcel = async () => {
    try {
      const url = `${API_URL}/api/buybox-reports/${reportId}?page=1&pageSize=9999999`
      const resp = await axios.get(url)
      const allRows = resp.data.data || []
      if (!allRows.length) {
        alert('No rows to export.')
        return
      }

      const header = [
        'analysisId',
        'asin',
        'seller1Name',
        'seller1PercentageWon',
        'seller1AvgPrice',
        'seller1IsFBA',
        'seller1AvgNewOfferCount',
        'seller2Name',
        'seller2PercentageWon',
        'seller2AvgPrice',
        'seller2IsFBA',
        'seller2AvgNewOfferCount',
        'seller3Name',
        'seller3PercentageWon',
        'seller3AvgPrice',
        'seller3IsFBA',
        'seller3AvgNewOfferCount',
        'seller4Name',
        'seller4PercentageWon',
        'seller4AvgPrice',
        'seller4IsFBA',
        'seller4AvgNewOfferCount',
        'seller5Name',
        'seller5PercentageWon',
        'seller5AvgPrice',
        'seller5IsFBA',
        'seller5AvgNewOfferCount',
        'seller6Name',
        'seller6PercentageWon',
        'seller6AvgPrice',
        'seller6IsFBA',
        'seller6AvgNewOfferCount',
        'seller7Name',
        'seller7PercentageWon',
        'seller7AvgPrice',
        'seller7IsFBA',
        'seller7AvgNewOfferCount',
        'seller8Name',
        'seller8PercentageWon',
        'seller8AvgPrice',
        'seller8IsFBA',
        'seller8AvgNewOfferCount',
        'seller9Name',
        'seller9PercentageWon',
        'seller9AvgPrice',
        'seller9IsFBA',
        'seller9AvgNewOfferCount',
        'seller10Name',
        'seller10PercentageWon',
        'seller10AvgPrice',
        'seller10IsFBA',
        'seller10AvgNewOfferCount',
      ]
      const rows: any[] = [header]

      for (const row of allRows) {
        const line: any[] = []
        line.push(row.analysisId || '', row.asin || '')

        for (let i = 1; i <= 10; i++) {
          const sName = row[`seller${i}Name`] || ''
          const sPct = row[`seller${i}PercentageWon`] || 0
          const sPrice = row[`seller${i}AvgPrice`] || 0
          const sFba = row[`seller${i}IsFBA`] ? 'Yes' : ''
          const sCount = row[`seller${i}AvgNewOfferCount`] || 0

          const sNameCell = sName === '' ? '' : sName
          const sPctCell = sPct === 0 ? '' : sPct
          const sPriceCell = sPrice === 0 ? '' : sPrice
          const sFbaCell = sFba === '' ? '' : sFba
          const sCountCell = sCount === 0 ? '' : sCount

          line.push(sNameCell)
          line.push(sPctCell)
          line.push(sPriceCell)
          line.push(sFbaCell)
          line.push(sCountCell)
        }
        rows.push(line)
      }

      const ws = XLSX.utils.aoa_to_sheet(rows)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, 'ReportDetail')
      XLSX.writeFile(wb, `BuyBoxReport_${reportId}.xlsx`)
    } catch (err) {
      console.error('Failed to export full data rows:', err)
      alert('Failed to export all rows. Check console for details.')
    }
  }

  return (
    <div style={{padding: '20px'}}>
      <button onClick={() => navigate('/buy_box_reports')} className='btn btn-secondary mb-3'>
        Back to Reports
      </button>
      <h3>Report {reportId} Details</h3>
      {error && <div className='alert alert-danger'>{error}</div>}
      {report && (
        <div className='mb-3'>
          <div>Status: {report.status}</div>
          <div>Created At: {report.createdAt}</div>
          <div>Total Asins: {report.totalAsins}</div>
          <div>Completed Asins: {report.completedAsins}</div>
        </div>
      )}

      <button className='btn btn-success mb-3' onClick={handleExportExcel}>
        Download Excel
      </button>
      <DataTable
        value={dataRows}
        paginator
        rows={pageSize}
        totalRecords={total}
        first={(page - 1) * pageSize}
        onPage={onPageChange}
        lazy
        loading={loading}
        responsiveLayout='scroll'
      >
        <Column field='analysisId' header='analysisId' />
        <Column field='asin' header='asin' />
        <Column field='seller1Name' header='seller1Name' />
        <Column field='seller1PercentageWon' header='s1Pct' />
        <Column field='seller1AvgPrice' header='s1Price' />
        <Column field='seller1IsFBA' header='s1FBA' />
        <Column field='seller1AvgNewOfferCount' header='s1NOC' />
        <Column field='seller2Name' header='seller2Name' />
        <Column field='seller2PercentageWon' header='s2Pct' />
        <Column field='seller2AvgPrice' header='s2Price' />
        <Column field='seller2IsFBA' header='s2FBA' />
        <Column field='seller2AvgNewOfferCount' header='s2NOC' />
        <Column field='seller3Name' header='seller3Name' />
        <Column field='seller3PercentageWon' header='s3Pct' />
        <Column field='seller3AvgPrice' header='s3Price' />
        <Column field='seller3IsFBA' header='s3FBA' />
        <Column field='seller3AvgNewOfferCount' header='s3NOC' />
        <Column field='seller4Name' header='seller4Name' />
        <Column field='seller4PercentageWon' header='s4Pct' />
        <Column field='seller4AvgPrice' header='s4Price' />
        <Column field='seller4IsFBA' header='s4FBA' />
        <Column field='seller4AvgNewOfferCount' header='s4NOC' />
        <Column field='seller5Name' header='seller5Name' />
        <Column field='seller5PercentageWon' header='s5Pct' />
        <Column field='seller5AvgPrice' header='s5Price' />
        <Column field='seller5IsFBA' header='s5FBA' />
        <Column field='seller5AvgNewOfferCount' header='s5NOC' />
        <Column field='seller6Name' header='seller6Name' />
        <Column field='seller6PercentageWon' header='s6Pct' />
        <Column field='seller6AvgPrice' header='s6Price' />
        <Column field='seller6IsFBA' header='s6FBA' />
        <Column field='seller6AvgNewOfferCount' header='s6NOC' />
        <Column field='seller7Name' header='seller7Name' />
        <Column field='seller7PercentageWon' header='s7Pct' />
        <Column field='seller7AvgPrice' header='s7Price' />
        <Column field='seller7IsFBA' header='s7FBA' />
        <Column field='seller7AvgNewOfferCount' header='s7NOC' />
        <Column field='seller8Name' header='seller8Name' />
        <Column field='seller8PercentageWon' header='s8Pct' />
        <Column field='seller8AvgPrice' header='s8Price' />
        <Column field='seller8IsFBA' header='s8FBA' />
        <Column field='seller8AvgNewOfferCount' header='s8NOC' />
        <Column field='seller9Name' header='seller9Name' />
        <Column field='seller9PercentageWon' header='s9Pct' />
        <Column field='seller9AvgPrice' header='s9Price' />
        <Column field='seller9IsFBA' header='s9FBA' />
        <Column field='seller9AvgNewOfferCount' header='s9NOC' />
        <Column field='seller10Name' header='seller10Name' />
        <Column field='seller10PercentageWon' header='s10Pct' />
        <Column field='seller10AvgPrice' header='s10Price' />
        <Column field='seller10IsFBA' header='s10FBA' />
        <Column field='seller10AvgNewOfferCount' header='s10NOC' />
      </DataTable>
    </div>
  )
}

// src/app/pages/dashboard/Apps/ProductDetailsModal.tsx

import React, {FC} from 'react'
import {Dialog} from 'primereact/dialog'
import {Button} from 'primereact/button'
import './ProductDetailsModal.css'

interface ProductDetailsModalProps {
  visible: boolean
  onHide: () => void
  productData: any // or a more specific interface if you have one
}

export const ProductDetailsModal: FC<ProductDetailsModalProps> = ({
  visible,
  onHide,
  productData,
}) => {
  if (!productData) {
    return null
  }

  // For images
  const mainImageUrl = productData.image_url1
    ? formatImageUrl(productData.image_url1)
    : 'https://via.placeholder.com/200'

  // Additional images
  const secondaryImages = [productData.image_url2, productData.image_url3]
    .filter(Boolean)
    .map((url) => formatImageUrl(url))

  // Helper to standardize image url
  function formatImageUrl(originalUrl: string) {
    if (!originalUrl) return 'https://via.placeholder.com/50'
    const hasProtocol = originalUrl.startsWith('http://') || originalUrl.startsWith('https://')
    if (hasProtocol) return originalUrl
    if (originalUrl.startsWith('/')) {
      return `${window.location.origin}${originalUrl}`
    }
    return `https://${originalUrl}`
  }

  // Build a list of all data fields on the product to display
  // You can control the order or grouping if you like.
  const allKeys = Object.keys(productData)
  // For an 80% wide modal
  const dialogStyle = {width: '80vw', maxWidth: '1200px'}

  const footer = (
    <div>
      <Button label='Close' icon='pi pi-times' onClick={onHide} className='p-button-text' />
    </div>
  )

  return (
    <Dialog
      visible={visible}
      onHide={onHide}
      header='Product Details'
      style={dialogStyle}
      footer={footer}
    >
      <div className='product-details-modal-content'>
        {/* Main product image */}
        <div className='product-main-image'>
          <img
            src={mainImageUrl}
            alt={productData.name || 'Main Image'}
            style={{
              width: '100%',
              height: 'auto',
              objectFit: 'contain',
              background: 'white',
              borderRadius: '10px',
            }}
          />
        </div>

        {/* Additional thumbnails */}
        {secondaryImages.length > 0 && (
          <div className='product-thumbnails'>
            {secondaryImages.map((url, idx) => (
              <img
                key={idx}
                src={url}
                alt={`Additional image ${idx + 1}`}
                style={{
                  width: '50%',
                  height: 'auto',
                  objectFit: 'contain',
                  background: 'white',
                  marginRight: '8px',
                  borderRadius: '10px',
                }}
              />
            ))}
          </div>
        )}

        {/* Data fields grid */}
        <div className='product-data-grid'>
          {allKeys.map((key) => {
            const value = productData[key]
            return (
              <div
                className='product-data-row'
                style={{borderBottom: '1px dashed #ccc', padding: '10px 0'}}
                key={key}
              >
                <div className='product-data-label'>{key}</div>
                <div className='product-data-value'>{value || '—'}</div>
              </div>
            )
          })}
        </div>
      </div>
    </Dialog>
  )
}

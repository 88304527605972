/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {API_URL} from '../../modules/auth/core/_requests'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {FilterMatchMode} from 'primereact/api'
import {Toast} from 'primereact/toast'
import {InputText} from 'primereact/inputtext'
import {InputNumber} from 'primereact/inputnumber'
import {Dropdown} from 'primereact/dropdown'
import {Checkbox} from 'primereact/checkbox'
import {Button} from 'primereact/button'
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup'
import {Dialog} from 'primereact/dialog'

interface SalesAssociate {
  id: number
  staff_name: string
  shopify_staff_id: number
  commission_percentage: number
  file_id: number
  location: string
  is_active: boolean
}

const locationOptions = [
  {label: '530 Sawgrass Corporate Parkway', value: '530 Sawgrass Corporate Parkway'},
  {label: 'R001 - POV Sawgrass', value: 'R001 - POV Sawgrass'},
  {label: 'R002 - Just One Look', value: 'R002 - Just One Look'},
  {label: 'R003 - Eyes on Lincoln', value: 'R003 - Eyes on Lincoln'},
  {label: 'R004 - PV2 Dolphin', value: 'R004 - PV2 Dolphin'},
  {label: 'R005 - DE Mall of San Juan', value: 'R005 - DE Mall of San Juan'},
  {label: 'R006 - DE Brickell', value: 'R006 - DE Brickell'},
  {label: 'R008 - DE Florida Mall', value: 'R008 - DE Florida Mall'},
  {label: 'R009 - Aventura', value: 'R009 - Aventura'},
  {label: 'R010 - PV3 iDrive', value: 'R010 - PV3 iDrive'},
  {label: 'R011 - PV4 Vineland', value: 'R011 - PV4 Vineland'},
  {label: 'R014 - DE Plaza Las Americas', value: 'R014 - DE Plaza Las Americas'},
]

export const SalesAssociatePage: FC = () => {
  const toast = useRef<Toast>(null)
  const [associates, setAssociates] = useState<SalesAssociate[]>([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState<any>({
    staff_name: {value: null, matchMode: FilterMatchMode.CONTAINS},
    location: {value: null, matchMode: FilterMatchMode.CONTAINS},
    is_active: {value: null, matchMode: FilterMatchMode.EQUALS},
  })
  const [showAddDialog, setShowAddDialog] = useState(false)
  const [newAssoc, setNewAssoc] = useState<SalesAssociate>({
    id: 0,
    staff_name: '',
    shopify_staff_id: 0,
    commission_percentage: 0,
    file_id: 0,
    location: '',
    is_active: true,
  })

  // Fetch existing associates
  const fetchAssociates = async () => {
    setLoading(true)
    try {
      const res = await axios.get<SalesAssociate[]>(`${API_URL}/api/sales-associates`)
      setAssociates(res.data || [])
    } catch (err) {
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Failed to fetch data'})
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchAssociates()
  }, [])

  // Save a cell, then refresh from DB
  const saveCell = async (rowData: SalesAssociate) => {
    setLoading(true)
    try {
      await axios.put(`${API_URL}/api/sales-associates/${rowData.id}`, {
        staff_name: rowData.staff_name,
        shopify_staff_id: rowData.shopify_staff_id,
        commission_percentage: rowData.commission_percentage,
        file_id: rowData.file_id,
        location: rowData.location,
        is_active: rowData.is_active,
      })
      toast.current?.show({severity: 'success', summary: 'Updated', detail: 'Cell updated'})
      await fetchAssociates()
    } catch (err) {
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Failed to update cell'})
    }
    setLoading(false)
  }

  // Add new associate (no field required)
  const addNew = async () => {
    setLoading(true)
    try {
      await axios.post(`${API_URL}/api/sales-associates`, {
        staff_name: newAssoc.staff_name,
        shopify_staff_id: newAssoc.shopify_staff_id,
        commission_percentage: newAssoc.commission_percentage,
        file_id: newAssoc.file_id,
        location: newAssoc.location,
        is_active: newAssoc.is_active,
      })
      toast.current?.show({
        severity: 'success',
        summary: 'Created',
        detail: 'New associate created',
      })
      setNewAssoc({
        id: 0,
        staff_name: '',
        shopify_staff_id: 0,
        commission_percentage: 0,
        file_id: 0,
        location: '',
        is_active: true,
      })
      setShowAddDialog(false)
      fetchAssociates()
    } catch (err) {
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Failed to add associate'})
    }
    setLoading(false)
  }

  // Delete
  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement>, row: SalesAssociate) => {
    confirmPopup({
      target: event.currentTarget,
      message: `Delete ${row.staff_name}?`,
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => handleDelete(row),
    })
  }

  const handleDelete = async (row: SalesAssociate) => {
    setLoading(true)
    try {
      await axios.delete(`${API_URL}/api/sales-associates/${row.id}`)
      setAssociates((prev) => prev.filter((x) => x.id !== row.id))
      toast.current?.show({
        severity: 'info',
        summary: 'Deleted',
        detail: row.staff_name + ' removed',
      })
    } catch (err) {
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Failed to delete'})
    }
    setLoading(false)
  }

  // Editors
  const textEditor = (options: any) => {
    const val = options.rowData[options.field]
    const handleBlur = async () => {
      setAssociates([...associates])
      await saveCell(options.rowData)
    }
    return (
      <InputText
        value={val}
        onChange={(e) => {
          options.rowData[options.field] = e.target.value
          setAssociates([...associates])
        }}
        onBlur={handleBlur}
      />
    )
  }

  const numberEditor = (options: any) => {
    const val = options.rowData[options.field]
    const handleBlur = async () => {
      setAssociates([...associates])
      await saveCell(options.rowData)
    }
    return (
      <InputNumber
        value={val}
        onValueChange={(e) => {
          options.rowData[options.field] = e.value ?? 0
          setAssociates([...associates])
        }}
        onBlur={handleBlur}
        useGrouping={false}
      />
    )
  }

  const commissionEditor = (options: any) => {
    const val = options.rowData[options.field]
    const handleBlur = async () => {
      setAssociates([...associates])
      await saveCell(options.rowData)
    }
    return (
      <InputNumber
        value={val}
        onValueChange={(e) => {
          options.rowData[options.field] = e.value ?? 0
          setAssociates([...associates])
        }}
        onBlur={handleBlur}
        mode='decimal'
        maxFractionDigits={2}
      />
    )
  }

  const dropdownEditor = (options: any) => {
    const val = options.rowData[options.field]
    const handleChange = (e: any) => {
      options.rowData[options.field] = e.value
      setAssociates([...associates])
      setTimeout(() => saveCell(options.rowData), 200)
    }
    return (
      <Dropdown
        value={val}
        options={locationOptions}
        onChange={handleChange}
        optionLabel='label'
        optionValue='value'
      />
    )
  }

  const checkboxEditor = (options: any) => {
    const val = options.rowData[options.field]
    const handleBlur = async () => {
      setAssociates([...associates])
      await saveCell(options.rowData)
    }
    return (
      <Checkbox
        checked={val}
        onChange={(e) => {
          options.rowData[options.field] = !!e.target.checked
          setAssociates([...associates])
        }}
        onBlur={handleBlur}
      />
    )
  }

  // Filter templates
  const locationFilter = (options: any) => (
    <Dropdown
      value={options.value}
      options={[{label: 'All', value: null}, ...locationOptions]}
      onChange={(e) => options.filterApplyCallback(e.value)}
      placeholder='Filter Location'
      className='p-column-filter'
      showClear
      optionLabel='label'
      optionValue='value'
    />
  )

  const activeFilter = (options: any) => {
    const boolOpts = [
      {label: 'All', value: null},
      {label: 'Active', value: true},
      {label: 'Inactive', value: false},
    ]
    return (
      <Dropdown
        value={options.value}
        options={boolOpts}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder='Filter Active'
        className='p-column-filter'
        showClear
        optionLabel='label'
        optionValue='value'
      />
    )
  }

  const nameFilter = (options: any) => (
    <InputText
      value={options.value || ''}
      onChange={(e) => options.filterApplyCallback(e.target.value)}
      placeholder='Search...'
      className='p-column-filter'
    />
  )

  const addDialogFooter = (
    <div className='d-flex justify-content-end'>
      <Button
        label='Cancel'
        className='p-button-text me-2'
        onClick={() => setShowAddDialog(false)}
      />
      <Button label='Save' className='p-button-primary' onClick={addNew} />
    </div>
  )

  return (
    <>
      <Toast ref={toast} />
      <ConfirmPopup />
      <div className='container mt-4'>
        <div className='row mb-3 d-flex align-items-center'>
          <div className='col'>
            <h3 className='m-0'>Sales Associates (No Required Fields)</h3>
          </div>
          <div className='col-auto'>
            <Button
              label='Add New Associate'
              icon='pi pi-plus'
              className='p-button-success'
              onClick={() => setShowAddDialog(true)}
            />
          </div>
        </div>
        <DataTable
          value={associates}
          dataKey='id'
          editMode='cell'
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          filters={filters}
          filterDisplay='row'
          loading={loading}
          responsiveLayout='scroll'
          sortMode='multiple'
        >
          <Column
            field='staff_name'
            header='Staff Name'
            editor={textEditor}
            filter
            filterPlaceholder='Name...'
            filterElement={nameFilter}
            showFilterMenu={false}
            sortable
            style={{width: '160px'}}
          />
          <Column
            field='shopify_staff_id'
            header='Shopify Staff ID'
            editor={numberEditor}
            sortable
            style={{width: '140px'}}
          />
          <Column
            field='commission_percentage'
            header='Commission %'
            editor={commissionEditor}
            sortable
            style={{width: '140px'}}
          />
          <Column
            field='file_id'
            header='File ID'
            editor={numberEditor}
            sortable
            style={{width: '120px'}}
          />
          <Column
            field='location'
            header='Location'
            editor={dropdownEditor}
            filter
            filterElement={locationFilter}
            showFilterMenu={false}
            sortable
            style={{width: '160px'}}
          />
          <Column
            field='is_active'
            header='Active'
            body={(rowData) => (rowData.is_active ? 'True' : 'False')}
            editor={checkboxEditor}
            filter
            filterElement={activeFilter}
            showFilterMenu={false}
            sortable
            style={{width: '100px'}}
          />
          <Column
            body={(rowData) => (
              <Button
                icon='pi pi-trash'
                className='p-button-text p-button-danger p-button-sm'
                onClick={(e) => confirmDelete(e, rowData)}
              />
            )}
            style={{width: '80px'}}
          />
        </DataTable>
      </div>

      <Dialog
        header='Add New Associate'
        visible={showAddDialog}
        style={{width: '500px'}}
        footer={addDialogFooter}
        onHide={() => setShowAddDialog(false)}
      >
        <div className='row g-3'>
          <div className='col-12'>
            <label className='form-label'>Staff Name</label>
            <InputText
              value={newAssoc.staff_name}
              onChange={(e) => setNewAssoc({...newAssoc, staff_name: e.target.value})}
              className='w-100'
            />
          </div>
          <div className='col-6'>
            <label className='form-label'>Shopify Staff ID</label>
            <InputNumber
              value={newAssoc.shopify_staff_id}
              onValueChange={(e) => setNewAssoc({...newAssoc, shopify_staff_id: e.value ?? 0})}
              min={0}
              className='w-100'
              useGrouping={false}
            />
          </div>
          <div className='col-6'>
            <label className='form-label'>Commission %</label>
            <InputNumber
              value={newAssoc.commission_percentage}
              onValueChange={(e) => setNewAssoc({...newAssoc, commission_percentage: e.value ?? 0})}
              mode='decimal'
              maxFractionDigits={2}
              className='w-100'
            />
          </div>
          <div className='col-6'>
            <label className='form-label'>File ID</label>
            <InputNumber
              value={newAssoc.file_id}
              onValueChange={(e) => setNewAssoc({...newAssoc, file_id: e.value ?? 0})}
              min={0}
              className='w-100'
              useGrouping={false}
            />
          </div>
          <div className='col-6'>
            <label className='form-label'>Location</label>
            <Dropdown
              value={newAssoc.location}
              options={locationOptions}
              onChange={(e) => setNewAssoc({...newAssoc, location: e.value})}
              optionLabel='label'
              optionValue='value'
              placeholder='(Optional location)'
              className='w-100'
            />
          </div>
          <div className='col-12 d-flex align-items-center mt-2'>
            <Checkbox
              checked={newAssoc.is_active}
              onChange={(e) => setNewAssoc({...newAssoc, is_active: !!e.target.checked})}
              inputId='newIsActive'
            />
            <label htmlFor='newIsActive' className='form-check-label ms-2'>
              Active
            </label>
          </div>
        </div>
      </Dialog>
    </>
  )
}

export const SalesAssociateWrapper: FC = () => {
  return (
    <>
      <h1 className='mt-3 ms-3'>Sales Associate</h1>
      <SalesAssociatePage />
    </>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState, useEffect, useRef} from 'react'
import axios from 'axios'
import {API_URL} from '../../modules/auth/core/_requests'
import {DataTable} from 'primereact/datatable'
import {Column} from 'primereact/column'
import {FilterMatchMode} from 'primereact/api'
import {Toast} from 'primereact/toast'
import {InputText} from 'primereact/inputtext'
import {Button} from 'primereact/button'
import {ConfirmPopup, confirmPopup} from 'primereact/confirmpopup'
import {Dialog} from 'primereact/dialog'

interface BrandTier {
  brand: string
  tier: string
}

export const BrandTierPage: FC = () => {
  const toast = useRef<Toast>(null)
  const [brandTiers, setBrandTiers] = useState<BrandTier[]>([])
  const [loading, setLoading] = useState(false)
  const [filters, setFilters] = useState<any>({
    brand: {value: null, matchMode: FilterMatchMode.CONTAINS},
    tier: {value: null, matchMode: FilterMatchMode.CONTAINS},
  })

  const [showAddDialog, setShowAddDialog] = useState(false)
  const [newRow, setNewRow] = useState<BrandTier>({
    brand: '',
    tier: '',
  })

  // Fetch from DB
  const fetchBrandTiers = async () => {
    setLoading(true)
    try {
      const resp = await axios.get<BrandTier[]>(`${API_URL}/api/brand-tiers`)
      setBrandTiers(resp.data || [])
    } catch (err) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to fetch Brand Tiers',
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    fetchBrandTiers()
  }, [])

  // Save a cell
  const saveCell = async (rowData: BrandTier) => {
    setLoading(true)
    try {
      await axios.put(`${API_URL}/api/brand-tiers/${encodeURIComponent(rowData.brand)}`, {
        brand: rowData.brand,
        tier: rowData.tier,
      })
      toast.current?.show({severity: 'success', summary: 'Updated', detail: 'Cell updated'})
      await fetchBrandTiers()
    } catch (err) {
      toast.current?.show({severity: 'error', summary: 'Error', detail: 'Failed to update cell'})
    }
    setLoading(false)
  }

  // Add new row (no required fields)
  const addNewBrandTier = async () => {
    setLoading(true)
    try {
      await axios.post(`${API_URL}/api/brand-tiers`, {
        brand: newRow.brand,
        tier: newRow.tier,
      })
      toast.current?.show({severity: 'success', summary: 'Created', detail: 'New Brand Tier added'})
      setNewRow({brand: '', tier: ''})
      setShowAddDialog(false)
      fetchBrandTiers()
    } catch (err) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to create Brand Tier',
      })
    }
    setLoading(false)
  }

  // Delete row
  const confirmDelete = (event: React.MouseEvent<HTMLButtonElement>, rowData: BrandTier) => {
    confirmPopup({
      target: event.currentTarget,
      message: `Delete brand "${rowData.brand}"?`,
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => deleteBrandTier(rowData),
    })
  }

  const deleteBrandTier = async (row: BrandTier) => {
    setLoading(true)
    try {
      await axios.delete(`${API_URL}/api/brand-tiers/${encodeURIComponent(row.brand)}`)
      setBrandTiers((prev) => prev.filter((b) => b.brand !== row.brand))
      toast.current?.show({severity: 'info', summary: 'Deleted', detail: 'Removed ' + row.brand})
    } catch (err) {
      toast.current?.show({
        severity: 'error',
        summary: 'Error',
        detail: 'Failed to delete Brand Tier',
      })
    }
    setLoading(false)
  }

  // Cell Editors
  const textEditor = (options: any) => {
    const val = options.rowData[options.field]
    const handleBlur = async () => {
      setBrandTiers([...brandTiers])
      await saveCell(options.rowData)
    }
    return (
      <InputText
        value={val}
        onChange={(e) => {
          options.rowData[options.field] = e.target.value
          setBrandTiers([...brandTiers])
        }}
        onBlur={handleBlur}
      />
    )
  }

  // Add Dialog footer
  const addDialogFooter = (
    <div className='d-flex justify-content-end'>
      <Button
        label='Cancel'
        className='p-button-text me-2'
        onClick={() => setShowAddDialog(false)}
      />
      <Button label='Save' className='p-button-primary' onClick={addNewBrandTier} />
    </div>
  )

  return (
    <>
      <Toast ref={toast} />
      <ConfirmPopup />

      <div className='container mt-4'>
        <div className='row mb-3'>
          <div className='col'>
            <h3>Brand Tiers</h3>
          </div>
          <div className='col-auto'>
            <Button
              label='Add Brand Tier'
              icon='pi pi-plus'
              className='p-button-success'
              onClick={() => setShowAddDialog(true)}
            />
          </div>
        </div>
        <DataTable
          value={brandTiers}
          dataKey='brand'
          editMode='cell'
          paginator
          rows={10}
          rowsPerPageOptions={[10, 20, 50]}
          filters={filters}
          filterDisplay='row'
          loading={loading}
          responsiveLayout='scroll'
          sortMode='multiple'
        >
          <Column
            field='brand'
            header='Brand'
            editor={textEditor}
            filter
            filterPlaceholder='Search brand'
            showFilterMenu={false}
            sortable
            style={{width: '200px'}}
          />
          <Column
            field='tier'
            header='Tier'
            editor={textEditor}
            filter
            filterPlaceholder='Search tier'
            showFilterMenu={false}
            sortable
            style={{width: '150px'}}
          />
          <Column
            body={(rowData) => (
              <Button
                icon='pi pi-trash'
                className='p-button-text p-button-danger p-button-sm'
                onClick={(e) => confirmDelete(e, rowData)}
              />
            )}
            style={{width: '70px'}}
          />
        </DataTable>
      </div>

      <Dialog
        visible={showAddDialog}
        header='Add New Brand Tier'
        onHide={() => setShowAddDialog(false)}
        style={{width: '400px'}}
        footer={addDialogFooter}
      >
        <div className='row g-3'>
          <div className='col-12'>
            <label className='form-label'>Brand</label>
            <InputText
              value={newRow.brand}
              onChange={(e) => setNewRow({...newRow, brand: e.target.value})}
              className='w-100'
            />
          </div>
          <div className='col-12'>
            <label className='form-label'>Tier</label>
            <InputText
              value={newRow.tier}
              onChange={(e) => setNewRow({...newRow, tier: e.target.value})}
              className='w-100'
            />
          </div>
        </div>
      </Dialog>
    </>
  )
}

export const BrandTiersWrapper: FC = () => {
  return (
    <>
      <h1 className='mt-3 ms-3'>Brand Tiers</h1>
      <BrandTierPage />
    </>
  )
}
